body{
	line-height: 28px;
	font-size: 16px;
	color: $font_color;
	font-family: 
	'Geometria',
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		Roboto,
		Helvetica Neue,
		Arial,
		Noto Sans,
		sans-serif,
		Apple Color Emoji,
		Segoe UI Emoji,
		Segoe UI Symbol,
		Noto Color Emoji;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	@media screen and (max-width: $mobile) {
		font-size: 16px;
	}
}
%header_title{
	font-weight: 800;
	font-size: 60px;
	line-height: 70px;
	max-width: 680px;
	font-weight: bold;
}
h1{
	@extend %header_title;
	padding-top: 50px;
	text-transform: uppercase;
	font-weight: 800;

	@media screen and (max-width: $tablet_hor) {
		font-size: 40px;
		line-height: 50px;
		padding-top: 10px;
		margin-bottom: 40px;
	}
	@media screen and (max-width: $mobile) {
		font-size: 30px;
		line-height: 40px;
		padding-top: 10px;
		margin-bottom: 40px;
	}

}
h2, .title{
	@extend %header_title;
	font-size: 34px;
	line-height: 40px;
	padding-top: 30px;
	@media screen and (max-width: $tablet_hor) {
		font-size: 24px;
		line-height: 30px;
		padding-top: 20px;
		margin-bottom: 20px;
	}
}
.product_options h1{
	padding-top: 10px;
}
p{
	max-width: $text_max_width;
	margin: 30px 0;
	&.max{
		font-size: 26px;
		line-height: 40px;
	}
}
b,strong{
	font-weight: bold;
}
a{
	color: $link_color;
	&:hover{
		text-decoration: none;
	}
}
.danger{
	color: $danger;
}
.success{
	color: $success;
}
.disable{
	color: $disable;
}
.text_min{
	font-size: 16px;
}
.error{
	color: $error;
}
blockquote{
	max-width: $text_max_width;
	box-sizing: border-box;
	font-size: 26px;
	line-height: 40px;
	padding: 0;
	margin: 90px 0;
}

ul{
	padding: 0;
	list-style: none;
	$list_mark_width:30px;
	max-width: $text_max_width;

	li{
		margin: 20px 0;
		padding: 0;
		padding-left: $list_mark_width + 20px;
		position: relative;

		&:before{
			content: '';
			top:14px;
			position: absolute;
			height: 1px;
			background: $font_color;
			width: $list_mark_width;
			left: 0;
		}
	}
}
