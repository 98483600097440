@charset "UTF-8";
/*
* Third Party
*/
@import url(../../bower_components/normalize.css/normalize.css);
/*
* Custom
*/
/****colors*****/
.global_overlay, .btn:after, .btn:before, .checkbox_wrapper span:after, .accordion .accordion_item header span:after, .accordion .accordion_item header span:before {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute; }

.checkbox_wrapper input[type="radio"] + span, .checkbox_wrapper input[type="radio"] + span:after, .color_item, .color_filter.checked:after {
  border-radius: 100%; }

.global_overlay {
  opacity: 0.8;
  background: #1B1B1B;
  position: fixed;
  z-index: 9; }

.cross_close:after, .cross_close:before, .hum div, .hum div:after, .hum div:before, .hum_2 div, .hum_2 div:after, .hum_2 div:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 2px;
  width: 22px;
  background: #424242;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }

@font-face {
  font-family: 'Geometria';
  src: url("../fonts/subset-Geometria-Regular.eot");
  src: url("../fonts/subset-Geometria-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Geometria-Regular.woff2") format("woff2"), url("../fonts/subset-Geometria-Regular.woff") format("woff"), url("../fonts/subset-Geometria-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Geometria';
  src: url("../fonts/subset-Geometria-ExtraBold.eot");
  src: url("../fonts/subset-Geometria-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Geometria-ExtraBold.woff2") format("woff2"), url("../fonts/subset-Geometria-ExtraBold.woff") format("woff"), url("../fonts/subset-Geometria-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Geometria';
  src: url("../fonts/subset-Geometria-Bold.eot");
  src: url("../fonts/subset-Geometria-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Geometria-Bold.woff2") format("woff2"), url("../fonts/subset-Geometria-Bold.woff") format("woff"), url("../fonts/subset-Geometria-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

body.hidden-scroll {
  overflow: hidden; }

.sl-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #424242;
  opacity: 0.9;
  display: none;
  z-index: 1035; }

.sl-wrapper {
  z-index: 1040; }
  .sl-wrapper * {
    box-sizing: border-box; }
  .sl-wrapper button {
    border: 0 none;
    background: transparent;
    font-size: 28px;
    padding: 0;
    cursor: pointer; }
    .sl-wrapper button:hover {
      opacity: 0.7; }
  .sl-wrapper .sl-close {
    display: none;
    position: fixed;
    right: 30px;
    top: 30px;
    z-index: 10060;
    margin-top: -14px;
    margin-right: -14px;
    height: 44px;
    width: 44px;
    line-height: 44px;
    font-family: Arial, Baskerville, monospace;
    color: #fff;
    font-size: 3rem; }
    .sl-wrapper .sl-close:focus {
      outline: none; }
  .sl-wrapper .sl-counter {
    display: none;
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 1060;
    color: #fff;
    font-size: 1rem; }
  .sl-wrapper .sl-navigation {
    width: 100%;
    display: none; }
    .sl-wrapper .sl-navigation button {
      position: fixed;
      top: 50%;
      margin-top: -22px;
      height: 44px;
      width: 22px;
      line-height: 44px;
      text-align: center;
      display: block;
      z-index: 10060;
      font-family: Arial, Baskerville, monospace;
      color: #fff; }
      .sl-wrapper .sl-navigation button.sl-next {
        right: 5px;
        font-size: 2rem; }
      .sl-wrapper .sl-navigation button.sl-prev {
        left: 5px;
        font-size: 2rem; }
      .sl-wrapper .sl-navigation button:focus {
        outline: none; }
      @media (min-width: 35.5em) {
        .sl-wrapper .sl-navigation button {
          width: 44px; }
          .sl-wrapper .sl-navigation button.sl-next {
            right: 10px;
            font-size: 3rem; }
          .sl-wrapper .sl-navigation button.sl-prev {
            left: 10px;
            font-size: 3rem; } }
      @media (min-width: 50em) {
        .sl-wrapper .sl-navigation button {
          width: 44px; }
          .sl-wrapper .sl-navigation button.sl-next {
            right: 20px;
            font-size: 3rem; }
          .sl-wrapper .sl-navigation button.sl-prev {
            left: 20px;
            font-size: 3rem; } }
  .sl-wrapper.sl-dir-rtl .sl-navigation {
    direction: ltr; }
  .sl-wrapper .sl-image {
    position: fixed;
    -ms-touch-action: none;
    touch-action: none;
    z-index: 10000; }
    .sl-wrapper .sl-image img {
      margin: 0;
      padding: 0;
      display: block;
      border: 0 none;
      width: 100%;
      height: auto; }
      @media (min-width: 35.5em) {
        .sl-wrapper .sl-image img {
          border: 0 none; } }
      @media (min-width: 50em) {
        .sl-wrapper .sl-image img {
          border: 0 none; } }
    .sl-wrapper .sl-image iframe {
      background: #000;
      border: 0 none; }
      @media (min-width: 35.5em) {
        .sl-wrapper .sl-image iframe {
          border: 0 none; } }
      @media (min-width: 50em) {
        .sl-wrapper .sl-image iframe {
          border: 0 none; } }
    .sl-wrapper .sl-image .sl-caption {
      display: none;
      padding: 10px;
      color: #fff;
      background: rgba(0, 0, 0, 0.8);
      font-size: 1rem;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }
      .sl-wrapper .sl-image .sl-caption.pos-top {
        bottom: auto;
        top: 0; }
      .sl-wrapper .sl-image .sl-caption.pos-outside {
        bottom: auto; }
    .sl-wrapper .sl-image .sl-download {
      display: none;
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: #fff;
      z-index: 1060; }

.sl-spinner {
  display: none;
  border: 5px solid #333;
  border-radius: 40px;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  position: fixed;
  top: 50%;
  width: 40px;
  z-index: 1007;
  -webkit-animation: pulsate 1s ease-out infinite;
  -moz-animation: pulsate 1s ease-out infinite;
  -ms-animation: pulsate 1s ease-out infinite;
  -o-animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite; }

.sl-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.sl-transition {
  transition: -moz-transform ease 200ms;
  transition: -ms-transform ease 200ms;
  transition: -o-transform ease 200ms;
  transition: -webkit-transform ease 200ms;
  transition: transform ease 200ms; }

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

@-moz-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

@-o-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

@-ms-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

body {
  line-height: 28px;
  font-size: 16px;
  color: #424242;
  font-family: 'Geometria', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: normal;
  -webkit-font-smoothing: antialiased; }
  @media screen and (max-width: 540px) {
    body {
      font-size: 16px; } }

h1, h2, .title {
  font-weight: 800;
  font-size: 60px;
  line-height: 70px;
  max-width: 680px;
  font-weight: bold; }

h1 {
  padding-top: 50px;
  text-transform: uppercase;
  font-weight: 800; }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 40px;
      line-height: 50px;
      padding-top: 10px;
      margin-bottom: 40px; } }
  @media screen and (max-width: 540px) {
    h1 {
      font-size: 30px;
      line-height: 40px;
      padding-top: 10px;
      margin-bottom: 40px; } }

h2, .title {
  font-size: 34px;
  line-height: 40px;
  padding-top: 30px; }
  @media screen and (max-width: 1024px) {
    h2, .title {
      font-size: 24px;
      line-height: 30px;
      padding-top: 20px;
      margin-bottom: 20px; } }

.product_options h1 {
  padding-top: 10px; }

p {
  max-width: 680px;
  margin: 30px 0; }
  p.max {
    font-size: 26px;
    line-height: 40px; }

b, strong {
  font-weight: bold; }

a {
  color: #3B73D5; }
  a:hover {
    text-decoration: none; }

.danger {
  color: #FD7D08; }

.success {
  color: #0FCA5D; }

.disable {
  color: #C0C0C0; }

.text_min {
  font-size: 16px; }

.error {
  color: #FF3C0F; }

blockquote {
  max-width: 680px;
  box-sizing: border-box;
  font-size: 26px;
  line-height: 40px;
  padding: 0;
  margin: 90px 0; }

ul {
  padding: 0;
  list-style: none;
  max-width: 680px; }
  ul li {
    margin: 20px 0;
    padding: 0;
    padding-left: 50px;
    position: relative; }
    ul li:before {
      content: '';
      top: 14px;
      position: absolute;
      height: 1px;
      background: #424242;
      width: 30px;
      left: 0; }

input, textarea {
  border-radius: 0 !important; }
  input::placeholder, textarea::placeholder {
    color: #DDD9CD; }

button:focus {
  outline: none; }

.btn, .btn:after, .btn:before {
  padding: 22px 40px 20px; }

.btn.mid, .btn.mid:after, .btn.mid:before {
  padding: 14px 20px;
  font-size: 10px; }

.btn.min, .btn.min:after, .btn.min:before {
  padding: 4px 20px;
  font-size: 10px; }

.btn {
  color: #424242;
  display: inline-flex;
  border: 1px solid #424242;
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  background: none;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 800;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }
  .btn:after {
    content: attr(data-text);
    color: #fff;
    transform: translateY(30%);
    opacity: 0; }
  .btn:before {
    content: '';
    color: #fff;
    background: #424242;
    transform: translateY(-101%);
    transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }
  .btn:hover:after {
    transform: translate(0);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9);
    transition-delay: 0.2s; }
  .btn:hover:before {
    transform: translate(0);
    opacity: 1; }
  .btn.primary {
    background: #FFC600;
    border: none; }
    .btn.primary:hover {
      border-color: #424242; }
  .btn.max {
    display: block;
    width: 100%; }
  .btn.dark_theme {
    border-color: #fff;
    color: #fff; }
    .btn.dark_theme:before {
      background: #fff; }
    .btn.dark_theme:after {
      color: #FFC600; }

.button_link {
  display: inline-flex;
  color: #424242;
  padding: 12px 0;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9);
  text-decoration: none !important;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1; }
  .button_link:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 1px;
    background: #424242;
    width: 100%;
    transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }
  .button_link:hover {
    color: #1B1B1B; }

.checkbox_wrapper {
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  padding: 0 0 0 33px;
  position: relative;
  margin: 9px 0; }
  .checkbox_wrapper span {
    border: 1px solid #DDD9CD;
    width: 18px;
    height: 18px;
    display: block;
    position: absolute;
    left: 0;
    top: -1px;
    transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9);
    transition-duration: 0.2s;
    background: #fff; }
    .checkbox_wrapper span:after {
      content: '';
      background-color: #424242;
      width: 18px;
      height: 18px;
      opacity: 0; }
  .checkbox_wrapper.disabled {
    color: #C0C0C0; }
    .checkbox_wrapper.disabled:hover span {
      background: #fff; }
  .checkbox_wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    box-sizing: border-box; }
    .checkbox_wrapper input:checked + span {
      border-color: #424242; }
      .checkbox_wrapper input:checked + span:after {
        opacity: 1; }
    .checkbox_wrapper input[type="checkbox"] + span:after {
      background-image: url(../img/check.svg);
      background-position: center;
      background-repeat: no-repeat; }
    .checkbox_wrapper input[type="radio"] + span:after {
      transform: scale(0.6); }
  .checkbox_wrapper:hover span {
    background: #F8F6F2; }

input, textarea {
  padding: 23px 28px 16px;
  line-height: 20px;
  border: none;
  font-size: 18px;
  outline: none; }

textarea {
  height: 110px;
  margin: 0;
  display: block; }

.input_wrapper {
  position: relative;
  margin-bottom: 20px;
  font-size: 16px;
  background: #fff;
  border: 1px solid #DDD9CD; }
  .input_wrapper input:focus + div, .input_wrapper textarea:focus + div, .input_wrapper.active input + div,
  .input_wrapper.active textarea + div {
    transform: translateY(20px);
    opacity: 0; }
  .input_wrapper input:focus ~ span, .input_wrapper textarea:focus ~ span, .input_wrapper.active input ~ span,
  .input_wrapper.active textarea ~ span {
    transform: translateY(0px);
    opacity: 1;
    transition-delay: 0.2s; }
  .input_wrapper div, .input_wrapper span {
    transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9);
    color: #8f8f8f; }
  .input_wrapper span {
    position: absolute;
    display: block;
    top: 0px;
    left: 28px;
    transform: translateY(-10px);
    opacity: 0;
    z-index: 1;
    font-size: 12px; }
  .input_wrapper input, .input_wrapper textarea {
    background: none;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    z-index: 2;
    position: relative;
    transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }
    .input_wrapper input:focus, .input_wrapper textarea:focus {
      box-shadow: 0 0 10px 0 rgba(255, 198, 0, 0.2);
      border-color: #FFC600; }
  .input_wrapper .input_value {
    position: absolute;
    top: 0;
    padding: 20px 28px;
    line-height: 20px; }
  .input_wrapper.error {
    color: #FF3C0F;
    background: #FFF3F1;
    border-color: #FF3C0F; }
    .input_wrapper.error input {
      border-color: #FF3C0F;
      color: #FF3C0F; }

.error_message {
  background: #FF3C0F;
  color: #fff !important;
  padding: 0 40px;
  font-size: 16px; }

.select_wrapper {
  font-size: 16px;
  line-height: 20px;
  position: relative;
  display: inline-block;
  margin-bottom: 20px; }
  .select_wrapper.active {
    z-index: 999; }
    .select_wrapper.active .select_dropdown {
      display: block; }
    .select_wrapper.active .select_form {
      border-color: #FFC600; }
      .select_wrapper.active .select_form:after {
        transform: rotate(180deg); }
  .select_wrapper:hover .select_form {
    border-color: #FFC600; }
  .select_wrapper .select_form {
    border: 1px solid #DDD9CD;
    padding: 20px 40px;
    padding-right: 60px;
    cursor: pointer;
    position: relative;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }
    .select_wrapper .select_form:after {
      content: '';
      background: url(../img/arrow_2.svg) center no-repeat;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 17px;
      width: 20px;
      text-align: center; }
  .select_wrapper .select_dropdown {
    box-shadow: 0 7px 30px 0 rgba(123, 76, 21, 0.08);
    border: 1px solid #DDD9CD;
    position: absolute;
    left: 0;
    right: 0;
    display: none;
    background: #fff;
    animation-name: select_dd;
    animation-duration: 0.3s;
    min-width: 180px;
    max-height: 300px;
    overflow-x: scroll; }
    .select_wrapper .select_dropdown .option {
      padding: 10px 20px;
      cursor: pointer;
      display: flex; }
      .select_wrapper .select_dropdown .option:hover {
        background: #F8F6F2; }

@keyframes select_dd {
  0% {
    opacity: 0;
    transform: translateY(40px); } }
  .select_wrapper .color_item {
    transform: translateY(1px); }

.pagination {
  display: flex;
  align-items: stretch;
  font-weight: 700; }
  .pagination .pagination_btn {
    padding: 0px 30px;
    border: 1px solid #DDD9CD;
    align-items: center;
    display: flex;
    text-decoration: none;
    color: #424242;
    font-size: 14px;
    transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }
    .pagination .pagination_btn:hover {
      background: #F8F6F2; }
  .pagination .select_wrapper {
    margin: 0 -1px; }
  .pagination .select_dropdown {
    min-width: auto; }

.color_item {
  height: 16px;
  width: 16px;
  min-width: 16px !important;
  display: inline-flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 10px 0 0; }

.color_filter {
  cursor: pointer;
  position: relative; }
  .color_filter.checked:after {
    content: '';
    position: absolute;
    border: 1px solid #1B1B1B;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px; }

.social_link {
  display: inline-block;
  padding: 10px;
  opacity: 0.4;
  margin: 20px 0 0 0;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }
  .social_link svg {
    fill: #3B73D5;
    width: 20px;
    height: 20px;
    display: block; }
  .social_link:hover {
    opacity: 1; }

@keyframes modal_overlay {
  from {
    opacity: 0; } }

.modal {
  position: fixed;
  top: 0;
  z-index: 10;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch; }
  .modal .modal_content_wrapper {
    width: 100%;
    margin: auto; }
  .modal.active {
    display: flex;
    align-items: stretch;
    justify-content: flex-end; }
    .modal.active.center .cross_close {
      position: absolute; }
    .modal.active.center .modal_content {
      margin: 0 7%; }

.modal_content {
  text-align: left;
  background: #fff;
  padding: 0 50px 50px;
  overflow: hidden;
  min-height: 100%;
  min-width: 100px;
  height: inherit;
  box-sizing: border-box;
  animation: modal_content 1.3s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative; }
  .modal_content header {
    padding: 30px 0; }
    .modal_content header .title {
      padding: 20px 0; }
  @media screen and (max-width: 540px) {
    .modal_content {
      padding: 0 4%; }
      .modal_content header .title {
        padding: 0; }
      .modal_content .btn {
        width: 100%; } }

@keyframes modal_content {
  0% {
    transform: translateY(20px) scale(0.95);
    opacity: 0; } }

.cross_close {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer; }
  .cross_close:after {
    transform: rotate(45deg);
    animation: close_1 1s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .cross_close:before {
    transform: rotate(-45deg);
    animation: close_1 1s cubic-bezier(0.165, 0.84, 0.44, 1); }

@keyframes close_1 {
  0% {
    width: 6px;
    opacity: 0; }
  70% {
    width: 6px;
    opacity: 0; } }

@keyframes close_2 {
  0% {
    transform: scale(0.5);
    opacity: 0; }
  50% {
    transform: scale(0.5);
    opacity: 0; } }
  @media screen and (max-width: 540px) {
    .cross_close {
      height: 65px; } }

.accordion .accordion_item header {
  background: #F8F6F2;
  padding: 10px 20px 10px 30px;
  position: relative;
  cursor: pointer;
  margin-bottom: 2px; }
  .accordion .accordion_item header span {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto; }
    .accordion .accordion_item header span:after, .accordion .accordion_item header span:before {
      content: '';
      width: 8px;
      height: 1px;
      position: absolute;
      background: #FFC600; }
    .accordion .accordion_item header span:before {
      transform: rotate(90deg); }

.accordion .accordion_item.active span:before {
  display: none; }

.accordion .accordion_item.active .accordion_item_content {
  display: block; }

.accordion .accordion_item .accordion_item_content {
  padding: 10px 30px;
  padding-bottom: 14px;
  display: none; }

.table_wrapper {
  overflow-x: scroll; }

table {
  width: 100%;
  border-collapse: collapse; }
  table thead {
    background: #F8F6F2;
    font-size: 16px; }
  table td {
    padding: 10px 30px;
    border-bottom: 1px solid #EBE9E2; }
  table .disabled {
    color: #C0C0C0; }

.cell_r {
  text-align: right; }

.cell_c {
  text-align: center; }

.order_history_table tr {
  cursor: pointer; }

.status_danger {
  color: #FD7D08; }

.status_success {
  color: #0FCA5D; }

.gdpr {
  position: fixed;
  bottom: 0;
  background: #FFC600;
  width: 100%;
  z-index: 5;
  color: #DDD9CD;
  font-size: 14px;
  line-height: 20px;
  animation-name: gdpr;
  animation-duration: 2s;
  display: none; }
  .gdpr .content {
    padding: 0px 7%; }
  .gdpr p {
    margin: 20px 0; }

@keyframes gdpr {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(100%); } }

.tabs_wrapper {
  display: flex;
  flex-direction: column;
  background: #fff; }
  .tabs_wrapper .tabs_header {
    display: flex;
    border-bottom: 1px solid #EBE9E2; }
    @media screen and (max-width: 768px) {
      .tabs_wrapper .tabs_header {
        overflow-x: scroll; } }
    .tabs_wrapper .tabs_header .tab_link {
      transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9);
      display: block;
      padding: 10px 0;
      margin: 0 20px;
      margin-bottom: -1px;
      cursor: pointer;
      color: #1B1B1B;
      text-transform: uppercase;
      font-size: 12px;
      position: relative; }
      .tabs_wrapper .tabs_header .tab_link:after {
        content: '';
        background: #FFC600;
        height: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute; }
      .tabs_wrapper .tabs_header .tab_link.active {
        color: #1B1B1B; }
        .tabs_wrapper .tabs_header .tab_link.active:after {
          height: 3px;
          transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }
  .tabs_wrapper .tabs_content_item {
    display: none;
    padding: 20px 0; }
    .tabs_wrapper .tabs_content_item.active {
      display: block; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li:before {
      display: none; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden; }
  body.no_scroll {
    overflow-y: hidden;
    height: 100%; }

.into {
  padding: 0 7%; }
  @media screen and (max-width: 540px) {
    .into {
      padding: 0 3.5%; } }

.col_2 {
  width: 50%; }
  @media screen and (max-width: 540px) {
    .col_2 {
      width: 100%; } }

.col_3 {
  width: 33.33333%; }

.col_3x2 {
  width: 66.66666%; }

.col_4 {
  width: 25%; }
  @media screen and (max-width: 768px) {
    .col_4 {
      width: 50%; } }
  @media screen and (max-width: 540px) {
    .col_4 {
      width: 46%; } }

.p_l {
  padding-left: 10%; }

.p_r {
  padding-right: 10%; }

.col_4x3 {
  width: 75%; }
  @media screen and (max-width: 768px) {
    .col_4x3 {
      width: auto; } }

.img_wrapper img {
  display: block;
  width: 100%; }

@media screen and (max-width: 540px) {
  .col_3 {
    width: 100%; } }

.header {
  position: absolute;
  left: 0;
  right: 0; }
  .header .into {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .header .top {
    background: #424242;
    color: #fff;
    font-size: 12px;
    padding: 3px 0; }
    .header .top .contact {
      font-weight: 700;
      display: flex;
      align-items: center; }
      @media screen and (max-width: 768px) {
        .header .top .contact {
          display: none; } }
      .header .top .contact a {
        color: #fff;
        text-decoration: none;
        margin-left: 10px;
        transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9);
        padding-left: 15px; }
        .header .top .contact a:hover {
          color: #FFC600; }
      .header .top .contact .phones {
        background: url(../img/phone.svg) no-repeat left center; }
      .header .top .contact .mail {
        border-right: 1px solid rgba(248, 246, 242, 0.3);
        margin-right: 20px;
        padding-right: 20px;
        background: url(../img/mail.svg) no-repeat left center; }
  .header .nav_ctn {
    padding: 30px 0; }
    .header .nav_ctn .nav {
      max-width: 100%;
      display: flex;
      font-weight: 700;
      font-size: 11px;
      text-transform: uppercase;
      align-items: center; }
      @media screen and (max-width: 1360px) {
        .header .nav_ctn .nav {
          display: none; } }
      .header .nav_ctn .nav a {
        color: #424242;
        text-decoration: none;
        padding: 15px 13px;
        line-height: 15px;
        display: block;
        transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }
        .header .nav_ctn .nav a:hover {
          color: #FFC600; }
      .header .nav_ctn .nav .btn {
        margin: 0 0 0 10px; }
      .header .nav_ctn .nav li {
        margin: 0;
        padding: 0; }
        .header .nav_ctn .nav li:before {
          display: none; }
        .header .nav_ctn .nav li.dd > a {
          position: relative;
          z-index: 2; }
          .header .nav_ctn .nav li.dd > a:after {
            content: '';
            background: url(../img/arrow_2.svg) no-repeat center;
            padding: 4px;
            margin-left: 2px; }
        .header .nav_ctn .nav li.dd:hover {
          z-index: 4; }
          .header .nav_ctn .nav li.dd:hover a {
            color: #FFC600; }
          .header .nav_ctn .nav li.dd:hover > div {
            display: block; }
            .header .nav_ctn .nav li.dd:hover > div:after {
              animation: nav_pl 0.4s forwards cubic-bezier(0.42, 0, 0.35, 0.9); }
            .header .nav_ctn .nav li.dd:hover > div a {
              animation: nav_a 0.6s forwards cubic-bezier(0.42, 0, 0.35, 0.9);
              color: #b3b3b3; }
        .header .nav_ctn .nav li.dd > div {
          position: absolute;
          display: none;
          padding: 0 0 20px 0; }
          .header .nav_ctn .nav li.dd > div a {
            padding: 10px 12px;
            z-index: 2;
            position: relative;
            color: #fff;
            font-weight: normal; }
            .header .nav_ctn .nav li.dd > div a:hover {
              color: #FFC600; }
          .header .nav_ctn .nav li.dd > div:after {
            content: '';
            background: #424242;
            position: absolute;
            top: -50px;
            bottom: 0px;
            left: -20px;
            right: -20px;
            box-shadow: 0 10px 50px 0 rgba(66, 66, 66, 0.28); }

@keyframes nav_pl {
  0% {
    transform: scale(1.1) translateY(-10%);
    opacity: 0; } }

@keyframes nav_a {
  0% {
    opacity: 0; }
  20% {
    opacity: 0; } }
  .header .logo {
    height: 40px;
    width: 220px;
    background: url(../img/logo.svg) left center no-repeat;
    background-size: contain; }
    @media screen and (max-width: 540px) {
      .header .logo {
        width: 160px; } }

.dd {
  font-weight: 700; }
  .dd .dd_link {
    cursor: pointer; }
    .dd .dd_link:after {
      content: '';
      background: url(../img/arrow_2_w.svg);
      margin-left: 4px;
      height: 5px;
      width: 5px; }
  .dd .dd_list {
    display: none;
    position: absolute;
    flex-direction: column;
    background: #424242;
    padding: 10px 15px;
    transform: translateX(-15px); }
    .dd .dd_list a {
      color: #fff;
      text-decoration: none; }
  .dd:hover .dd_list {
    display: flex; }
  .dd .lang_link {
    display: flex;
    align-items: center; }
    .dd .lang_link:before {
      content: '';
      width: 18px;
      height: 12px;
      margin-right: 8px;
      transform: translateY(-1px);
      background-size: cover;
      background-repeat: no-repeat;
      display: block;
      background-position: left center; }
    .dd .lang_link.by:before {
      background-image: url(../img/flag_by.svg); }
    .dd .lang_link.pl:before {
      background-image: url(../img/flag_pl.svg); }
    .dd .lang_link.lt:before {
      background-image: url(../img/flag_lt.svg); }
    .dd .lang_link.ua:before {
      background-image: url(../img/flag_ua.svg); }
    .dd .lang_link.ru:before {
      background-image: url(../img/flag_ru.svg); }

.dark_top .header .top {
  background: rgba(66, 66, 66, 0.4); }

.dark_top .header .logo {
  background-image: url(../img/logo_w.svg);
  display: block; }

.dark_top .header .nav_ctn a {
  color: #fff; }

.dark_top .header .nav_ctn .nav .btn {
  background: #fff;
  border: none; }

.dark_top .header .nav_ctn .nav li.dd a:after {
  background-image: url(../img/arrow_2_w.svg); }

.dark_top .header .hum div, .dark_top .header .hum div:after, .dark_top .header .hum div:before {
  background-color: #fff; }

.hum {
  cursor: pointer;
  display: none;
  align-items: center;
  height: 40px;
  width: 40px;
  transform: translateX(9px);
  background: none;
  border: none; }
  @media screen and (max-width: 1360px) {
    .hum {
      display: block; } }
  .hum div {
    position: relative; }
    .hum div:after {
      transform: translateY(-6px); }
    .hum div:before {
      transform: translateY(6px); }

.hum_2 {
  border: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 7%;
  height: 80px;
  z-index: 3;
  display: none;
  background: #fff; }
  .hum_2 div {
    position: relative;
    background: #424242; }
    .hum_2 div:after {
      transform: translateY(-6px); }
    .hum_2 div:before {
      transform: translateY(6px); }
  .hum_2.active {
    display: block;
    transform: translateY(0);
    animation: hum_ani 0.5s cubic-bezier(0.42, 0, 0.35, 0.9) forwards; }
  @media screen and (max-width: 540px) {
    .hum_2 {
      width: 14%;
      height: 50px; } }

@keyframes hum_ani {
  0% {
    transform: translateY(-100%); } }

.footer {
  background: #F8F6F2;
  font-size: 12px;
  line-height: 20px; }
  .footer .top {
    padding: 80px 0 0 0; }
    .footer .top .footer_logo {
      width: 220px;
      height: 40px;
      background: url(../img/logo.svg) left center no-repeat;
      background-size: contain; }
    @media screen and (max-width: 540px) {
      .footer .top {
        padding: 40px 0 0 0; }
        .footer .top .footer_logo {
          width: 190px;
          height: 30px; } }
  .footer a {
    color: #424242;
    text-decoration: none; }
  .footer .footer_nav {
    display: flex;
    font-weight: bold;
    padding: 40px 0 80px;
    flex-wrap: wrap; }
    @media screen and (max-width: 768px) {
      .footer .footer_nav .col_4 {
        width: 100%; }
        .footer .footer_nav .col_4:nth-child(4) {
          display: flex; }
          .footer .footer_nav .col_4:nth-child(4) b {
            width: 50%;
            padding-right: 20px;
            box-sizing: border-box; } }
    @media screen and (max-width: 768px) {
      .footer .footer_nav .nav {
        width: 50%;
        margin: 40px 0; } }
    .footer .footer_nav .nav a {
      display: inline-flex;
      margin-bottom: 10px; }
    .footer .footer_nav .phones {
      margin-top: 30px; }
      @media screen and (max-width: 768px) {
        .footer .footer_nav .phones {
          margin-top: 0; } }
  .footer .bottom {
    border-top: 1px solid #e6dfd1;
    display: flex;
    padding: 50px 0; }
    @media screen and (max-width: 768px) {
      .footer .bottom {
        flex-direction: column;
        padding: 30px 0; }
        .footer .bottom > div {
          width: auto;
          text-align: center;
          order: 2; } }
    .footer .bottom .social_links {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px; }
      @media screen and (max-width: 768px) {
        .footer .bottom .social_links {
          order: 1;
          margin-bottom: 10px; } }
      .footer .bottom .social_links a {
        margin: 0 30px 0 0; }
        @media screen and (max-width: 540px) {
          .footer .bottom .social_links a {
            margin: 0 10px; } }

.benefits {
  padding: 90px 0; }
  .benefits .into {
    display: flex;
    flex-wrap: wrap; }
    .benefits .into .item {
      box-sizing: border-box;
      position: relative;
      padding: 0 40px 40px 0;
      font-size: 14px;
      line-height: 25px; }
      @media screen and (max-width: 540px) {
        .benefits .into .item {
          width: 100%;
          padding: 0 0 10px;
          margin: 20px 0; } }
      .benefits .into .item header {
        font-weight: 700;
        line-height: 40px;
        font-size: 28px;
        margin: 0 0 20px 0; }
        @media screen and (max-width: 1024px) {
          .benefits .into .item header {
            font-size: 24px;
            line-height: 30px;
            padding-top: 20px;
            margin-bottom: 20px; } }
        @media screen and (max-width: 540px) {
          .benefits .into .item header {
            padding-top: 0;
            margin-bottom: 10px; } }
      .benefits .into .item:before {
        font-weight: 800;
        font-size: 70px;
        color: #F8F6F2;
        display: block;
        margin: 0 0 10px -5px;
        line-height: 1; }
        @media screen and (max-width: 540px) {
          .benefits .into .item:before {
            margin-bottom: 0;
            font-size: 60px; } }
      .benefits .into .item:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 15%;
        background: #FFC600; }
      .benefits .into .item:nth-child(1):before {
        content: '01.'; }
      .benefits .into .item:nth-child(2):before {
        content: '02.'; }
      .benefits .into .item:nth-child(3):before {
        content: '03.'; }
      .benefits .into .item:nth-child(4):before {
        content: '04.'; }

.top_block {
  padding: 200px 0 40px; }
  @media screen and (max-width: 540px) {
    .top_block {
      padding: 120px 0 40px; } }
  .top_block h1 {
    margin-top: 0px;
    padding-top: 30px;
    max-width: 100%;
    margin-right: 25%; }
    @media screen and (max-width: 1024px) {
      .top_block h1 {
        padding-bottom: 10px;
        margin: 0 0 10px 0; } }

.dark_top .top_block {
  background-color: black;
  background-size: cover;
  background-position: center;
  margin-bottom: 90px; }
  @media screen and (max-width: 540px) {
    .dark_top .top_block {
      margin-bottom: 30px; } }

.dark_top .top_block_content {
  margin-left: 25%; }
  @media screen and (max-width: 1024px) {
    .dark_top .top_block_content {
      margin-left: 0; }
      .dark_top .top_block_content h1 {
        margin-right: 0; } }
  .dark_top .top_block_content h1 {
    color: #fff; }

.numbers {
  color: #fff;
  font-weight: 700;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 -60px 0;
  transform: translateY(90px); }
  @media screen and (max-width: 540px) {
    .numbers {
      transform: translateY(80px);
      margin-top: 0;
      margin-bottom: 40px; }
      .numbers .into {
        padding: 0; } }
  .numbers .into {
    display: flex; }
  .numbers .item {
    width: 25%;
    box-sizing: border-box;
    padding: 60px; }
    @media screen and (max-width: 1024px) {
      .numbers .item {
        padding: 5%;
        width: 100%; } }
  @media screen and (max-width: 1024px) and (max-width: 540px) {
    .numbers .item {
      text-align: center; } }
    .numbers .item b {
      display: block;
      font-size: 60px;
      line-height: 1;
      margin-bottom: 10px; }
      @media screen and (max-width: 1024px) {
        .numbers .item b {
          font-size: 38px; } }
    .numbers .item.item_1 {
      margin-left: 25%;
      background: #FFC600; }
      @media screen and (max-width: 1024px) {
        .numbers .item.item_1 {
          margin: 0; } }
    .numbers .item.item_2 {
      background: #ffce24; }
      .numbers .item.item_2:after {
        content: '2';
        background: #fff;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 71.5%; }
        @media screen and (max-width: 1024px) {
          .numbers .item.item_2:after {
            display: none; } }

@media screen and (max-width: 768px) {
  .gallery_container {
    flex-direction: column; } }

@media screen and (max-width: 540px) {
  .gallery_container aside {
    width: 100%; } }

.gallery_container aside h2 {
  margin-top: 0;
  padding-top: 0; }

.gallery_container .grid_nav {
  margin-bottom: 40px; }

.gallery_container .gallery_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .gallery_container .gallery_wrapper .item {
    display: block;
    width: 30%;
    margin-bottom: 40px;
    margin-right: 5%;
    font-weight: 700;
    color: #424242;
    text-decoration: none; }
    .gallery_container .gallery_wrapper .item:nth-child(3n+3) {
      margin-right: 0; }
    @media screen and (max-width: 540px) {
      .gallery_container .gallery_wrapper .item {
        width: 100%;
        margin-right: 0; } }
    .gallery_container .gallery_wrapper .item img {
      width: 100%;
      margin-bottom: 20px;
      display: block; }

.gallery_container .pagination {
  padding: 50px 0; }

#m_menu .modal_content_wrapper {
  display: flex;
  justify-content: flex-end; }

#m_menu .modal_content {
  min-height: 100vh;
  max-width: 600px;
  right: 0;
  padding: 0;
  position: relative;
  animation-name: m_menu;
  animation-duration: 0.7s; }

@keyframes m_menu {
  0% {
    transform: translateX(90px);
    opacity: 0; } }

#m_menu .nav {
  padding-top: 0px;
  padding-bottom: 50px;
  margin: 0; }
  #m_menu .nav li {
    padding: 0;
    border-bottom: 1px solid #EBE9E2;
    margin: 0; }
    #m_menu .nav li:before {
      display: none; }
    #m_menu .nav li a {
      color: #424242;
      text-decoration: none;
      font-weight: normal;
      display: block;
      padding: 20px 7%;
      text-transform: uppercase; }
    #m_menu .nav li > a {
      font-weight: 700;
      line-height: 12px;
      font-size: 11px; }
    #m_menu .nav li.dd {
      padding-bottom: 20px; }
      #m_menu .nav li.dd div a {
        padding: 2px 40px;
        font-size: 11px; }

#m_menu .m_menu_contacts {
  padding: 30px 7% 30px;
  background: #F8F6F2;
  line-height: 25px;
  font-size: 14px; }
  #m_menu .m_menu_contacts a {
    text-decoration: none;
    font-weight: 700;
    color: #424242; }

#svg_logo {
  fill: #666;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 0 20px 0; }

.home_1 {
  display: flex;
  margin-top: -90px; }
  @media screen and (max-width: 540px) {
    .home_1 {
      flex-wrap: wrap;
      margin-top: -30px; } }
  .home_1 .col_2 {
    box-sizing: border-box;
    padding: 180px 7% 80px; }
    .home_1 .col_2 h2 {
      margin-top: 0; }
    .home_1 .col_2:last-child {
      padding-left: 10%; }
    .home_1 .col_2:first-child {
      background: #F8F6F2; }
    @media screen and (max-width: 540px) {
      .home_1 .col_2 {
        width: 100%;
        padding: 40px 3.5%; }
        .home_1 .col_2:last-child {
          padding-left: 3.5%; } }
  .home_1 .award_block {
    display: flex;
    margin-top: 60px;
    font-size: 14px;
    align-items: center;
    line-height: 25px; }
    @media screen and (max-width: 540px) {
      .home_1 .award_block {
        flex-wrap: wrap; } }
    .home_1 .award_block .img_wrapper {
      margin-right: 20px;
      width: 80%; }
      .home_1 .award_block .img_wrapper img {
        width: 70%; }
      @media screen and (max-width: 540px) {
        .home_1 .award_block .img_wrapper {
          margin: 0 auto; }
          .home_1 .award_block .img_wrapper img {
            margin: auto; } }
    .home_1 .award_block p {
      width: auto; }

.clients {
  padding: 80px 0 60px;
  counter-reset: orderedlist; }
  .clients .into {
    display: flex;
    flex-wrap: wrap; }
    @media screen and (max-width: 540px) {
      .clients .into {
        justify-content: space-between; } }
  .clients .item .wrapper {
    padding: 40px;
    margin-right: 30px;
    margin-bottom: 30px;
    background: #F8F6F2;
    font-size: 14px;
    line-height: 20px;
    position: relative; }
    @media screen and (max-width: 540px) {
      .clients .item .wrapper {
        margin-right: 0;
        padding: 20px; } }
    .clients .item .wrapper:after {
      counter-increment: orderedlist;
      content: "0" counter(orderedlist);
      position: absolute;
      top: 40px;
      left: 40px;
      font-size: 12px;
      opacity: 0.6; }
      @media screen and (max-width: 540px) {
        .clients .item .wrapper:after {
          left: 20px;
          top: 20px; } }
    .clients .item .wrapper div:last-child {
      transform: translateY(5px); }
  .clients .item .logo {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: grayscale(1);
    transition: all 0.3s cubic-bezier(0.42, 0, 0.35, 0.9); }
    .clients .item .logo img {
      width: 100%;
      max-width: 120px;
      margin: auto;
      display: block;
      transform: translateY(5px); }
  .clients .item:hover .logo {
    filter: grayscale(0); }

.home_grid {
  padding: 90px 0; }
  @media screen and (max-width: 540px) {
    .home_grid {
      padding: 0px 0 30px; }
      .home_grid .col_2 {
        width: 100%; } }
  .home_grid .into:first-child {
    display: flex; }
    @media screen and (max-width: 540px) {
      .home_grid .into:first-child {
        flex-wrap: wrap; } }
  .home_grid .img_wrapper {
    display: block;
    margin: 80px 0;
    text-decoration: none; }
  .home_grid .item_1 {
    margin-top: 80px; }
  .home_grid .item_2 {
    padding-left: 10%;
    box-sizing: border-box; }
  .home_grid .item_3 {
    margin: auto;
    padding: 0 30px 0 0;
    box-sizing: border-box; }
  @media screen and (max-width: 540px) {
    .home_grid .item_1, .home_grid .item_2, .home_grid .item_3 {
      margin: 10px 0 30px !important;
      padding: 0; } }
  .home_grid button {
    margin-top: 20px; }

.ui_kit_container {
  padding: 0 10% 10%; }
  .ui_kit_container h2 {
    margin: 100px 0 40px 0;
    padding-top: 20px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.bread_crumbs {
  display: flex; }
  .bread_crumbs a, .bread_crumbs span {
    color: #424242;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 230px;
    overflow: hidden; }
    .bread_crumbs a:after, .bread_crumbs span:after {
      content: '';
      background: url("../img/arrow.svg") center no-repeat;
      width: 40px;
      display: inline-flex;
      height: 7px; }
    .bread_crumbs a:last-child:after, .bread_crumbs span:last-child:after {
      display: none; }

.dark_top .bread_crumbs a, .dark_top .bread_crumbs span {
  color: rgba(255, 255, 255, 0.8); }
  .dark_top .bread_crumbs a:after, .dark_top .bread_crumbs span:after {
    background: url("../img/arrow_w.svg") center no-repeat;
    opacity: 0.6; }

.cta {
  margin-top: 60px;
  position: relative; }
  .cta h2 {
    margin-top: 0;
    padding-top: 0; }
  .cta .into {
    display: flex;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EBE9E2;
    box-shadow: 0 7px 30px 0 rgba(123, 76, 21, 0.08);
    margin: 0 7%;
    position: relative;
    z-index: 2; }
    .cta .into .col_2 {
      padding: 130px 0;
      padding-right: 40px; }
      .cta .into .col_2:first-child p {
        max-width: 260px; }
    @media screen and (max-width: 540px) {
      .cta .into {
        flex-direction: column;
        margin: 0 3.5%;
        padding: 60px 30px; }
        .cta .into .col_2 {
          padding: 0px 0; }
        .cta .into .btn {
          width: 100%;
          display: block; } }
  .cta:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 70%;
    background: #FFC600; }
  .cta form.col_2.clear {
    text-align: center;
    padding-right: 0;
    color: #0FCA5D;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .cta form.col_2.clear:before {
      content: '';
      background: #0FCA5D url(../img/check.svg) center no-repeat;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-bottom: 5px; }

.gallery_min {
  padding: 40px 0; }
  .gallery_min .into {
    display: flex;
    align-items: center;
    position: relative; }
    @media screen and (max-width: 540px) {
      .gallery_min .into {
        flex-direction: column;
        padding-bottom: 60px; }
        .gallery_min .into .col_4 {
          width: 100%; }
        .gallery_min .into .button_link {
          position: absolute;
          bottom: 0; } }
    .gallery_min .into .img {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
      padding-left: 30px; }
      @media screen and (max-width: 540px) {
        .gallery_min .into .img {
          padding: 0 0 10px; } }
      .gallery_min .into .img img {
        width: inherit;
        display: block;
        width: 100%; }
    .gallery_min .into .title {
      margin: 0px 0 20px 0;
      padding-top: 10px; }

.catalogue_teaser {
  padding: 90px 0; }
  @media screen and (max-width: 540px) {
    .catalogue_teaser {
      padding: 30px 0; } }
  .catalogue_teaser .teaser {
    display: flex;
    background: #FFFFFF;
    border: 1px solid #EBE9E2;
    box-shadow: 0 7px 30px 0 rgba(123, 76, 21, 0.08);
    margin: 40px 0 20px 0; }
    @media screen and (max-width: 540px) {
      .catalogue_teaser .teaser {
        overflow-x: scroll; } }
    .catalogue_teaser .teaser .item {
      display: block;
      position: relative;
      box-sizing: border-box;
      padding: 40px;
      font-size: 14px;
      line-height: 25px;
      text-decoration: none;
      color: #424242; }
      @media screen and (max-width: 540px) {
        .catalogue_teaser .teaser .item {
          padding: 8%;
          width: 400px; }
          .catalogue_teaser .teaser .item .des {
            min-width: 200px; } }
      .catalogue_teaser .teaser .item:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 1px;
        background: #EBE9E2; }
      .catalogue_teaser .teaser .item:last-child:after {
        display: none; }
      .catalogue_teaser .teaser .item .img_wrapper {
        display: block;
        margin: 0 0 40px 0; }
        .catalogue_teaser .teaser .item .img_wrapper img {
          max-width: 100%;
          margin: auto;
          display: block; }
  .catalogue_teaser .bottom {
    text-align: right; }

.bl {
  display: flex;
  align-items: center;
  padding: 90px 0; }
  .bl .col_2 {
    box-sizing: border-box; }
    .bl .col_2 img {
      width: 100%; }
  @media screen and (max-width: 540px) {
    .bl {
      flex-direction: column;
      padding: 20px 0; }
      .bl .p_l, .bl .p-r {
        padding: 0; } }

.stat h2, .stat h3, .stat p, .stat ul, .stat .spec {
  width: 50%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 768px) {
    .stat h2, .stat h3, .stat p, .stat ul, .stat .spec {
      width: auto; } }

.stat .spec > div {
  margin: 20px 0; }
  .stat .spec > div div:last-child {
    text-align: right;
    padding-right: 0;
    padding-left: 20px;
    white-space: nowrap; }

.news {
  padding-bottom: 80px; }
  .news .into {
    display: flex;
    flex-wrap: wrap; }
    .news .into .news_prev {
      box-sizing: border-box;
      margin-bottom: 40px; }
      @media screen and (max-width: 540px) {
        .news .into .news_prev p {
          margin-bottom: 0; } }
      .news .into .news_prev .img_wrapper {
        margin-bottom: 20px;
        display: block; }
      .news .into .news_prev h2 {
        margin-top: 0px;
        padding-top: 10px; }
        @media screen and (max-width: 540px) {
          .news .into .news_prev h2 {
            padding-top: 0; } }
      @media screen and (min-width: 541px) {
        .news .into .news_prev:nth-child(odd) {
          padding-right: 20px; }
        .news .into .news_prev:nth-child(even) {
          padding-left: 20px; } }
      .news .into .news_prev .des {
        text-decoration: none;
        color: #424242; }
  .news.similar {
    padding-top: 90px; }

.date {
  font-size: 12px; }

.grid_nav {
  border-bottom: 1px solid #EBE9E2;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 540px) {
    .grid_nav {
      margin-bottom: 20px; } }
  .grid_nav a {
    text-decoration: none;
    color: #424242;
    font-size: 14px;
    padding: 15px 0;
    display: block;
    margin-right: 30px;
    position: relative; }
    @media screen and (max-width: 540px) {
      .grid_nav a {
        line-height: 1;
        margin-right: 15px;
        font-size: 12px; } }
    .grid_nav a.active:after {
      content: '';
      position: absolute;
      height: 2px;
      bottom: -1px;
      background: #FFC600;
      left: 0;
      right: 0; }

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .grid .item {
    box-shadow: 0 7px 30px 0 rgba(123, 76, 21, 0.08);
    border: 1px solid #EBE9E2;
    width: 48%;
    box-sizing: border-box;
    padding: 50px;
    margin: 30px 0; }
    @media screen and (max-width: 1024px) {
      .grid .item {
        padding: 20px 7%; } }
    @media screen and (max-width: 540px) {
      .grid .item {
        width: 100%;
        margin: 10px 0;
        font-size: 14px;
        line-height: 20px; } }
    .grid .item .spec {
      font-size: 14px;
      line-height: 20px; }
    .grid .item .img_wrapper {
      display: block;
      box-sizing: border-box;
      padding: 30px; }
      .grid .item .img_wrapper img {
        display: block;
        margin: auto;
        width: 100%;
        max-width: 200px; }
        @media screen and (max-width: 540px) {
          .grid .item .img_wrapper img {
            max-width: 160px; } }
    .grid .item .title {
      display: block;
      text-decoration: none;
      color: #424242;
      font-size: 20px;
      margin-bottom: 10px;
      line-height: 30px; }

.p_wrapper {
  padding: 90px 0; }
  @media screen and (max-width: 540px) {
    .p_wrapper {
      padding: 40px 0; } }

.rating {
  background: url(../img/rating_star_2.svg) left center;
  height: 16px;
  width: 76px;
  font-size: 14px; }
  .rating i {
    background: #fff url(../img/rating_star.svg) left center;
    height: inherit;
    display: block; }

.opt {
  display: flex;
  align-items: center;
  padding: 30px 0 10px; }
  @media screen and (max-width: 1024px) {
    .opt {
      align-items: flex-start;
      flex-wrap: wrap; } }
  .opt .btn {
    margin: 0; }
    @media screen and (max-width: 1024px) {
      .opt .btn {
        display: block;
        width: 100%;
        order: 2; } }
  .opt .price {
    font-weight: 700;
    padding-left: 30px;
    line-height: 20px;
    box-sizing: border-box; }
    @media screen and (max-width: 1024px) {
      .opt .price {
        padding-left: 0;
        padding-right: 30px;
        margin-bottom: 20px; } }
    @media screen and (max-width: 540px) {
      .opt .price {
        width: 50%; } }
    .opt .price div {
      font-size: 14px;
      font-weight: normal;
      color: #666666; }
      @media screen and (max-width: 540px) {
        .opt .price div {
          font-size: 12px;
          line-height: 1; } }

.footnote {
  color: #666666;
  font-size: 14px;
  padding-left: 15px;
  position: relative;
  margin: 40px 0;
  line-height: 20px; }
  .footnote:before {
    content: '*';
    left: 0;
    position: absolute; }

.spec {
  margin-top: 20px;
  color: #666666;
  position: relative; }
  .spec > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    margin-bottom: 5px; }
    .spec > div:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 5px;
      right: 0;
      border-bottom: 1px dotted #DDD9CD; }
    .spec > div div {
      background-color: #fff;
      z-index: 3;
      position: relative;
      padding-right: 40px; }
      .spec > div div.rating {
        transform: translateY(3px);
        padding-right: 0; }

.product_ctn {
  padding-top: 0;
  align-items: flex-start; }
  .product_ctn .img_wrapper {
    height: 500px;
    overflow: hidden; }
    @media screen and (max-width: 540px) {
      .product_ctn .img_wrapper {
        height: auto; } }
    .product_ctn .img_wrapper img {
      display: flex;
      max-width: 100%;
      max-height: 100%;
      width: inherit;
      margin: auto; }

.product_page .grid {
  padding: 80px 0; }

.contacts {
  position: relative; }
  .contacts .into {
    display: flex; }
    @media screen and (max-width: 540px) {
      .contacts .into {
        flex-direction: column; }
        .contacts .into .p_r {
          padding: 0; } }
  .contacts:after {
    content: '';
    position: absolute;
    right: 0;
    width: 14%;
    background: #FFC600;
    top: 20%;
    height: 50%; }
    @media screen and (max-width: 540px) {
      .contacts:after {
        display: none; } }
  .contacts .col_2 {
    box-sizing: border-box; }
  .contacts form {
    box-sizing: border-box;
    padding: 60px;
    border: 1px solid #EBE9E2;
    box-shadow: 0 7px 30px 0 rgba(123, 76, 21, 0.08);
    margin-bottom: 90px;
    position: relative;
    z-index: 2;
    background: #fff; }
    @media screen and (max-width: 540px) {
      .contacts form {
        padding: 50px 7%; }
        .contacts form .btn {
          display: block;
          width: 100%; } }
    .contacts form h2 {
      margin-top: 0;
      padding-top: 0; }

.filters .item {
  margin: 0 0 40px; }
  .filters .item header {
    font-weight: 700;
    font-size: 16px;
    padding: 0 0 10px 0; }

@media screen and (max-width: 768px) {
  .filters {
    display: none; } }

@media screen and (min-width: 768px) {
  .filters .cross_close {
    display: none; } }

.filters.active {
  display: block;
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 20px;
  z-index: 10;
  margin: auto;
  padding-top: 60px; }

.filter_btn {
  border: 1px solid #424242;
  background: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 20px 0;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative; }
  .filter_btn:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: url(../img/ic_filter.svg) center no-repeat;
    right: 20px; }
  @media screen and (min-width: 768px) {
    .filter_btn {
      display: none; } }

.summary_bl {
  padding: 50px 0; }
  @media screen and (max-width: 768px) {
    .summary_bl {
      padding: 30px 0; } }
  .summary_bl .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 50px 0; }
    @media screen and (max-width: 768px) {
      .summary_bl .wrapper {
        margin: 0; } }
    @media screen and (max-width: 540px) {
      .summary_bl .wrapper {
        flex-direction: column; } }
    .summary_bl .wrapper .item {
      width: 30%;
      color: #424242;
      text-decoration: none;
      margin-right: 5%;
      position: relative;
      margin-bottom: 20px; }
      @media screen and (max-width: 540px) {
        .summary_bl .wrapper .item {
          width: 100%; } }
      .summary_bl .wrapper .item:nth-child(3n+3) {
        margin-right: 0; }
      .summary_bl .wrapper .item img {
        width: 100%;
        display: block; }
      .summary_bl .wrapper .item h3 {
        bottom: 0; }

.video_wrapper {
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9*/
  position: relative;
  margin: 60px 0; }
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

@media screen and (max-width: 540px) {
  .parallax_element {
    padding-top: 0px;
    transform: translateY(0) !important; } }

.terrazzo_grid {
  margin-bottom: 90px; }
  .terrazzo_grid .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    @media screen and (max-width: 540px) {
      .terrazzo_grid .gallery {
        justify-content: space-between; } }
    .terrazzo_grid .gallery .sl_item {
      display: block;
      width: calc(16.666666% - 50px);
      margin-bottom: 4%;
      margin-right: 50px; }
      .terrazzo_grid .gallery .sl_item:nth-child(6n+6) {
        border-color: yellow;
        margin-right: 0; }
      @media screen and (max-width: 540px) {
        .terrazzo_grid .gallery .sl_item {
          width: 30%;
          margin: 0 0 15px; } }
      .terrazzo_grid .gallery .sl_item img {
        display: block;
        width: 100%; }

@media screen and (max-width: 540px) {
  .pagination {
    justify-content: center; } }

#order_def .modal_content {
  max-width: 800px;
  margin: auto; }
  @media screen and (max-width: 540px) {
    #order_def .modal_content {
      padding-bottom: 30px; } }
  #order_def .modal_content .btn {
    display: block; }

#order_def .title {
  margin-bottom: 0;
  padding-bottom: 0; }

.b24-widget-button-position-bottom-right {
  bottom: 20px !important;
  right: 20px !important; }
