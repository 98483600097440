.tabs_wrapper{
	display: flex;
	flex-direction: column;
	background: #fff;

	.tabs_header{
		display: flex;
		border-bottom: 1px solid $border_color;
		
		@media screen and (max-width: $tablet_ver){
			overflow-x: scroll;
		}

		.tab_link{
			transition: $transition_1;
			display: block;
			padding: 10px 0;
			margin: 0 20px;
			margin-bottom: -1px;
			cursor: pointer;
			color: $black;
			text-transform:uppercase;
			font-size: 12px;
			position: relative;
			&:after{
				content: '';
				background: $prime_color;
				height: 0px;
				left: 0;
				right: 0;
				bottom: 0;
				position: absolute;
			}
			&.active{
				color: $black;
				&:after{
					height: 3px;
					transition: $transition_1;
				}
			}
		}
	}

	.tabs_content_item{
		display: none;
		padding: 20px 0;

		&.active{
			display: block;
		}
	}
}
