.gallery_container{
	// display: flex;
	@media screen and(max-width: $tablet_ver) {
		flex-direction: column;
	}
	aside{
		@media screen and (max-width: $mobile) {
			width: 100%
		}
		h2{
			margin-top: 0;
			padding-top: 0;
		}
	}
	.grid_nav{
		margin-bottom:40px;
	}
	.gallery_wrapper{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		.item{
			display: block;
			width: 30%;
			margin-bottom: 40px;
			margin-right: 5%;
			font-weight: 700;
			color: $font_color;
			text-decoration: none;
			&:nth-child(3n+3){
				margin-right:0;
			}
			@media screen and (max-width: $mobile) {
				width: 100%;
				margin-right:0;
			}
			img{
				width: 100%;
				margin-bottom: 20px;
				display: block;
			}
		}
	}
	.pagination{
		padding: 50px 0;
	}
}