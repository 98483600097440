.footer{
	background: $light_gray;
	font-size: 12px;
	line-height: 20px;
	.top{
		padding: 80px 0 0 0;

		.footer_logo{
			width: 220px;
			height: 40px;
			background: url(../img/logo.svg) left center no-repeat;
			background-size: contain;
		}
		@media screen and (max-width: $mobile){
			padding: 40px 0 0 0;
			.footer_logo{
				width: 190px;
				height: 30px;
			}
		}
	}
	a{
		color: $font_color;
		text-decoration: none;
	}
	.footer_nav{
		display: flex;
		font-weight: bold;
		padding: 40px 0 80px;
		flex-wrap: wrap;
		@media screen and (max-width: $tablet_ver) {
			.col_4{
				width: 100%;
				&:nth-child(4){
					display: flex;
					b{
						width: 50%;
						padding-right:20px;
						box-sizing: border-box;
					}
				}
			}
		}
		.nav{
			@media screen and (max-width: $tablet_ver) {
				width: 50%;
				margin: 40px 0;
			}
			a{
				display: inline-flex;
				margin-bottom: 10px;
			}	
		}
		.phones{
			margin-top: 30px;
			@media screen and (max-width: $tablet_ver) {
				margin-top: 0;
			}
		}	
	}
	.bottom{
		border-top: 1px solid darken($light_gray, 10%);
		display: flex;
		padding: 50px 0;
		@media screen and (max-width: $tablet_ver){
			flex-direction: column;
			padding: 30px 0;
			& > div{
				width: auto;
				text-align: center;
				order: 2;
			} 
		}
		.social_links{
			text-transform: uppercase;
			font-weight: bold;
			font-size: 11px;
			@media screen and (max-width: $tablet_ver){
				order: 1;
				margin-bottom: 10px;
			}
			a{
				margin: 0 30px 0 0;
				@media screen and (max-width: $mobile){
					margin: 0 10px;
				}
			}
		}
	}
}
