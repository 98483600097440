@font-face {
    font-family: 'Geometria';
    src: url('../fonts/subset-Geometria-Regular.eot');
    src: url('../fonts/subset-Geometria-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Geometria-Regular.woff2') format('woff2'),
        url('../fonts/subset-Geometria-Regular.woff') format('woff'),
        url('../fonts/subset-Geometria-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('../fonts/subset-Geometria-ExtraBold.eot');
    src: url('../fonts/subset-Geometria-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Geometria-ExtraBold.woff2') format('woff2'),
        url('../fonts/subset-Geometria-ExtraBold.woff') format('woff'),
        url('../fonts/subset-Geometria-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('../fonts/subset-Geometria-Bold.eot');
    src: url('../fonts/subset-Geometria-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Geometria-Bold.woff2') format('woff2'),
        url('../fonts/subset-Geometria-Bold.woff') format('woff'),
        url('../fonts/subset-Geometria-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
