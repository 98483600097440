.ui_kit_container{
	padding: 0 10% 10%;

	h2{
		margin: 100px 0 40px 0;
		padding-top: 20px;
		max-width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.bread_crumbs{
	display: flex;
	a, span{
		color: $font_color;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 10px;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 230px;
		overflow: hidden;
		&:after{
			content: '';
			background: url('../img/arrow.svg') center no-repeat;
			width: 40px;
			display: inline-flex;
			height: 7px;
		}
		&:last-child:after{
			display: none;
		}
	}
}

.dark_top{
	.bread_crumbs{
		a, span{
			color: rgba(#fff, 0.8);
			&:after{
				background: url('../img/arrow_w.svg') center no-repeat;
				opacity: 0.6;
			}
		}
	}
}

.cta{
	margin-top: 60px;
	position: relative;
	h2{
		margin-top: 0;
		padding-top: 0;
	}
	.into{
		display: flex;
		box-sizing: border-box;
		background: #FFFFFF;
		border: 1px solid #EBE9E2;
		box-shadow: 0 7px 30px 0 rgba(123,76,21,0.08);
		margin: 0 $page_padding;
		position: relative;
		z-index: 2;

		.col_2{
			padding: 130px 0;
			padding-right:40px;
			&:first-child{
				p{
					max-width: 260px;
				}
			}
		}
		@media screen and(max-width: $mobile) {
			flex-direction: column;
			margin: 0 $page_padding/2;
			padding: 60px 30px;
			.col_2{
				padding: 0px 0;
			}
			.btn{
				width: 100%;
				display: block;
			}
		}
	}
	&:after{
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		height: 70%;
		background:$prime_color;
	}
	form.col_2.clear{
		text-align:center;
		padding-right:0;
		color: $success;
		display: flex;
		align-items:center;
		justify-content: center;
		flex-direction: column;
		$icon_size:40px;
		&:before{
			content: '';
			background: $success url(../img/check.svg) center no-repeat;
			// background-size: 20px;
			width: $icon_size;
			height: $icon_size;
			border-radius: 100%;
			margin-bottom: 5px;
		}
	}
}

.gallery_min{
	padding: 40px 0;
	.into{
		display: flex;
		align-items: center;
		position:relative;
		@media screen and(max-width: $mobile) {
			flex-direction: column;
			padding-bottom:60px;
			.col_4{
				width: 100%;
			}
			.button_link{
				position:absolute;
				bottom:0;
			}
		}
		.img{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			box-sizing:border-box;
			padding-left: 30px;
			@media screen and(max-width: $mobile) {
				padding:0 0 10px;
			}
			img{
				width: inherit;
				display: block;
				width: 100%;
			}
		}
		.title{
			margin: 0px 0 20px 0;
			padding-top: 10px;
		}
	}
}
.catalogue_teaser{
	padding: 90px 0;
	@media screen and (max-width: $mobile){
		padding:30px 0;
	}
	.teaser{
		display: flex;
		background: #FFFFFF;
		border: 1px solid $border_color;
		box-shadow: $box-shadow;
		margin: 40px 0 20px 0;
		@media screen and (max-width: $mobile) {
			overflow-x: scroll;
		}
		.item{
			display: block;
			position:relative;
			box-sizing:border-box;
			padding: 40px;
			font-size: 14px;
			line-height: 25px;
			text-decoration: none;
			color: $font_color;
			@media screen and (max-width: $mobile) {
				padding: 8%;
				width: 400px;
				.des{
					min-width: 200px;
				}
			}
			&:after{
				content: '';
				position:absolute;
				top:0;
				bottom:0;
				right: 0;
				width: 1px;
				background: $border_color;
			}
			&:last-child:after{
				display: none;
			}
			.img_wrapper{
				display: block;
				margin: 0 0 40px 0;
				img{
					max-width: 100%;
					margin: auto;
					display: block;
				}
			}
		}
	}
	.bottom{
		text-align: right;
	}
}


.bl{
	display: flex;
	align-items: center;
	padding: 90px 0;
	.col_2{
		box-sizing:border-box;
		img{
			width: 100%;
		}
	}
	@media screen and (max-width: $mobile) {
		flex-direction: column;
		padding: 20px 0;
		.p_l, .p-r{
			padding:0;
		}
	}
}

.stat{
	h2, h3, p, ul, .spec{
		width: 50%;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		@media screen and(max-width: $tablet_ver) {
			width: auto;
		}
	}
	.spec{
		& > div{
			margin: 20px 0;
			& div:first-child{
				// margin-right: 20px;
			}
			& div:last-child{
				text-align: right;
				padding-right:0;
				padding-left:20px;
				white-space: nowrap;
			}
		}
	}
}


.news{
	padding-bottom: 80px;
	.into{
		display: flex;
		flex-wrap: wrap;

		.news_prev{
			box-sizing:border-box;
			margin-bottom: 40px;
			@media screen and (max-width: $mobile){
				p{
					margin-bottom: 0;
				}
			}
			.img_wrapper{
				margin-bottom: 20px;
				display: block;
			}
			h2{
				margin-top: 0px;
				padding-top:10px;
				@media screen and (max-width: $mobile){
					padding-top: 0;
				}
			}

			$news_prev_pad: 20px;

			@media screen and (min-width: $mobile + 1) {
	
				&:nth-child(odd){
					padding-right:$news_prev_pad;
				}
				&:nth-child(even){
					padding-left:$news_prev_pad;
				}
			}

			
			.des{
				text-decoration: none;
				color: $font_color;
			}
		}
	}
	&.similar{
		padding-top: 90px;
	}
}

.date{
	font-size: 12px;
}


.grid_nav{
	border-bottom:1px solid $border_color;
	font-weight: 700;
	text-transform: uppercase;
	display: flex;
	align-items:center;
	@media screen and (max-width: $mobile) {
		margin-bottom: 20px;
	}
	a{
		text-decoration: none;
		color: $font_color;
		font-size: 14px;
		padding: 15px 0;
		display: block;
		margin-right: 30px;
		position: relative;
		@media screen and (max-width: $mobile) {
			line-height: 1;
			margin-right:15px;
			font-size: 12px;
		}
		&.active{
			&:after{
				content: '';
				position:absolute;
				height: 2px;
				bottom:-1px;
				background:$prime_color;
				left: 0;
				right: 0;
			}
		}
	}
}

.grid{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.item{
		box-shadow: $box-shadow;
		border:1px solid $border_color;
		width: 48%;
		box-sizing:border-box;
		padding:50px;
		margin: 30px 0;
		@media screen and (max-width: $tablet_hor) {
			padding:20px $page_padding;
		}
		@media screen and (max-width: $mobile) {
			width: 100%;
			margin: 10px 0;
			font-size: 14px;
			line-height: 20px;
			
		}

		.spec{
			font-size: 14px;
			line-height: 20px;
		}

		.img_wrapper{
			display: block;
			box-sizing:border-box;
			padding:30px;
			img{
				display: block;
				margin: auto;
				width: 100%;
				max-width: 200px;
				@media screen and (max-width: $mobile){
					max-width: 160px;
				}
			}
		}
		.title{
			display: block;
			text-decoration: none;
			color: $font_color;
			font-size: 20px;
			margin-bottom: 10px;
			line-height: 30px;
		}
	}
}
.p_wrapper{
	padding: 90px 0;
	@media screen and (max-width: $mobile) {
		padding:40px 0;
	}
}

.rating{
	background: url(../img/rating_star_2.svg) left center;
	height: 16px;
	width: 76px;
	font-size: 14px;
	i{
		background:#fff url(../img/rating_star.svg) left center;
		height: inherit;
		display: block;
	}
}

.opt{
	display: flex;
	align-items: center;
	
	padding:30px 0 10px;
	@media screen and (max-width: $tablet_hor){
		align-items: flex-start;
		flex-wrap: wrap;
	}
	@media screen and (max-width: $mobile) {
		// flex-direction: column;
	}
	.btn{
		margin: 0;
		@media screen and (max-width: $tablet_hor) {
			display: block;
			width: 100%;
			order: 2;
		}
	}
	.price{
		font-weight: 700;
		padding-left: 30px;
		line-height: 20px;
		box-sizing:border-box;
		@media screen and (max-width: $tablet_hor) {
			padding-left:0;
			padding-right: 30px;
			margin-bottom: 20px;
		}

		@media screen and (max-width: $mobile) {
			width: 50%;
			
		}
		
			
		div{
			font-size: 14px;
			font-weight: normal;
			color: $font_light_clr;
			@media screen and (max-width: $mobile){
				font-size: 12px;
				line-height: 1;
			}
		}
	}
}

.footnote{
	color: $font_light_clr;
	font-size: 14px;
	padding-left: 15px;
	position:relative;
	margin: 40px 0;
	line-height: 20px;
	&:before{
		content: '*';
		left: 0;
		position: absolute;
	}
}

.spec{
	margin-top: 20px;
	color: $font_light_clr;
	position: relative;
	& > div{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		position:relative;
		margin-bottom: 5px;
		&:before{
			content: '';
			position:absolute;
			left: 0;
			bottom:5px;
			right: 0;
			border-bottom:1px dotted $border_color_dark;
		}
		div{
			background-color: #fff;
			z-index: 3;
			position:relative;
			padding-right: 40px;
			&.rating{
				transform: translateY(3px);
				padding-right:0;
			}
		}
	}
}

.product_ctn{
	padding-top: 0;
	align-items:flex-start;

	.img_wrapper{
		height: 500px;
		overflow: hidden;
		@media screen and (max-width: $mobile) {
			height: auto;
		}
		img{
			display: flex;
			max-width: 100%;
			max-height: 100%;
			width: inherit;
			margin: auto;
		}
	}
}

.product_page{
	.grid{
		padding:80px 0;
	}
}

.contacts{
	position:relative;

	.into{
		display: flex;
		@media screen and (max-width: $mobile) {
			flex-direction: column;
			.p_r{
				padding:0;
			}
		}
	}
	&:after{
		content: '';
		position:absolute;
		right: 0;
		width: $page_padding*2;
		background:$prime_color;
		top: 20%;
		height: 50%;
		@media screen and (max-width: $mobile) {
			display: none;
		}
	}
	.col_2{
		box-sizing:border-box;
	}
	form{
		box-sizing: border-box;
		padding:60px;
		border:1px solid $border_color;
		box-shadow: $box_shadow;
		margin-bottom: 90px;
		position:relative;
		z-index: 2;
		background:#fff;
		@media screen and (max-width: $mobile) {
			padding:50px $page_padding;
			.btn{
				display: block;
				width: 100%;
			}
		}

		h2{
			margin-top: 0;
			padding-top:0;
		}
		
		
	}
}

.filters{
	.item{
		margin: 0 0 40px;
		header{
			font-weight: 700;
			font-size: 16px;
			padding:0 0 10px 0;
		}
	}
	@media screen and(max-width: $tablet_ver) {
		display: none;
	}
	.cross_close{
		@media screen and(min-width: $tablet_ver) {
			display: none;
		}
	}

	&.active{
		display: block;
		position:fixed;
		background:#fff;
		top:0;
		left: 0;
		right: 0;
		box-sizing:border-box;
		padding: 20px;
		z-index:10;
		margin: auto;
		padding-top: 60px;
	}

}
.filter_btn{
	border: 1px solid $font_color;
	background:none;
	display: flex;
	width: 100%;
	align-items:center;
	justify-content: center;
	margin-bottom: 20px;
	padding: 20px 0;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	position: relative;
	&:after{
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		background:url(../img/ic_filter.svg) center no-repeat;
		right: 20px;
	}
	@media screen and(min-width: $tablet_ver) {
		display: none;
	}
}

.summary_bl{
	padding: 50px 0;
	@media screen and (max-width: $tablet_ver) {
		padding: 30px 0;
	}

	.wrapper{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 50px 0;
		@media screen and (max-width: $tablet_ver) {
			margin: 0;
		}
		@media screen and (max-width: $mobile) {
			flex-direction: column;
		}

		.item{
			width: 30%;
			color: $font_color;
			text-decoration: none;
			margin-right: 5%;
			position:relative;
			margin-bottom: 20px;

			@media screen and (max-width: $mobile) {
				width: 100%;

			}

			&:nth-child(3n+3){
				margin-right: 0;
			}

			img{
				width: 100%;
				display: block;
			}
			h3{
				bottom:0;
			}
		}
	}
}

.video_wrapper{
	overflow: hidden;
	padding-top: 56.25%; /* 16:9*/
	position: relative;
	margin: 60px 0;
	iframe{
		border: 0;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.parallax_element{
	@media screen and(max-width: $mobile) {
		padding-top:0px;
		transform:translateY(0) !important;
	}
}


.terrazzo_grid{
	margin-bottom: 90px;
	.gallery{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		@media screen and(max-width: $mobile) {
			justify-content: space-between;
		}
		.sl_item{
			display: block;
			width: calc(16.666666% - 50px);
			margin-bottom: 4%;
			margin-right: 50px;


			
			&:nth-child(6n+6){
				border-color: yellow;
				margin-right: 0;

			}
			@media screen and(max-width: $mobile) {
				width: 30%;
				margin: 0 0 15px;
			}
			img{
				display: block;
				width: 100%;
			}
		}
	}
}
.pagination{
	@media screen and(max-width: $mobile) {
		justify-content: center;
	}
}

#order_def{
	.modal_content{
		max-width: 800px;
		margin: auto;
		@media screen and (max-width: $mobile) {
			padding-bottom:30px;
		}
		.btn{
			display: block;
		}
	}
	.title{
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

.b24-widget-button-position-bottom-right{
	$b24_pos:20px;
	bottom: $b24_pos !important;
	right: $b24_pos !important;
}
