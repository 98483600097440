// $btn_padding: 18px 30px;
$ip_h:40px;
$ip_v:20px;
$input_margin_bottom:20px;
$check_size:18px;
$input_font_size:16px;
$btn_font_size: 12px;

input, textarea{
	border-radius: 0 !important;
	&::placeholder{
		color: $border_color_dark;
	}
}

button:focus{
	outline: none;
}

%button_padding{
	padding: $ip_v + 2px $ip_h $ip_v;
}
%button_padding_mid{
	padding: $ip_v - 6px $ip_h - 20px;
	font-size: $btn_font_size - 2;
}
%button_padding_min{
	padding: $ip_v - 16px $ip_h - 20px;
	font-size: $btn_font_size - 2;
}
.btn{
	@extend %button_padding;
	color: $font_color;
	display: inline-flex;
	border: 1px solid $font_color;
	font-size: $btn_font_size;
	line-height: 12px;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	outline: none;
	box-sizing: border-box;
	background: none;
	position: relative;
	overflow: hidden;
	border-radius: $border-radius;
	text-transform: uppercase;
	margin-bottom: 20px;
	font-weight: 800;
	transition: $transition_1;

	&:after{
		@extend %button_padding;
		content: attr(data-text);
		@extend %center_abs;
		color: #fff;
		transform: translateY(30%);
		opacity: 0;
	}
	&:before{
		@extend %button_padding;
		content: '';
		@extend %center_abs;
		color: #fff;
		background: $font_color;
		transform: translateY(-101%);
		transition: $transition_1;
	}
	&:hover:after{
		transform: translate(0);
		opacity: 1;
		transition: $transition_1;
		transition-delay: 0.2s;
	}
	&:hover:before{
		transform: translate(0);
		opacity: 1;
	}

	&.primary{
		background: $prime_color;
		border: none;

		&:hover{
			border-color: $font_color;
		}

		&:before{
			// background: $font_color;
		}
		&:after{
			// color: $prime_color;
		}
	}
	&.max{
		display: block;
		width: 100%;
	}
	&.dark_theme{
		border-color:#fff;
		color:#fff;
		&:before{
			background: #fff;
		}
		&:after{
			color: $prime_color;
		}
	}
	&.mid{
		@extend %button_padding_mid;
		&:after, &:before{
			@extend %button_padding_mid;
		}
	}
	&.min{
		@extend %button_padding_min;
		&:after, &:before{
			@extend %button_padding_min;
		}
	}
}

.button_link{
	display: inline-flex;
	color: $font_color;
	padding: $ip_v - 8px 0;
	background: transparent;
	border: none;
	cursor: pointer;
	position: relative;
	transition: $transition_1;
	text-decoration: none !important;
	font-weight:700;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 1;
	&:after{
		content: '';
		position: absolute;
		bottom: 0px;
		left: 0;
		height: 1px;
		background: $font_color;
		width: 100%;
		transition: $transition_1;
	}
	&:hover{
		color: $black;
		&:after{
			
		}
	}
}





.checkbox_wrapper{
	display: inline-block;
	font-size: $input_font_size;
	line-height: 18px;
	padding: 0 0 0 $check_size + 15;
	position: relative;
	margin: 9px 0;

	span{
		border:1px solid $border_color_dark;
		width: $check_size;
		height: $check_size;
		display: block;
		position: absolute;
		left: 0;
		top: -1px;
		transition: $transition_1;
		transition-duration: 0.2s;
		background: #fff;

		&:after{
			@extend %center_abs;
			content: '';
			background-color: $font_color;
			width: $check_size;
			height: $check_size;
			opacity: 0;
		}
	}

	&.disabled{
		color: $disable;

		&:hover span{
			background: #fff;
		}
	}

	input{
		opacity: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		box-sizing: border-box;

		&:checked + span{
			border-color: $font_color;

			&:after{
				opacity: 1;
			}
		}

		&[type="checkbox"] + span{
			&:after{
				background-image: url(../img/check.svg);
				background-position: center;
				background-repeat: no-repeat;
			}
		} 

		&[type="radio"] + span{
			@extend %radius_100;

			&:after{
				transform: scale(0.6);
				@extend %radius_100;
			}
		}

	}
	&:hover span{
		background: $sec_color; 
	}


}

input, textarea{
	padding: $ip_v + 3px $ip_h - 12px $ip_v - 4px;
	line-height: 20px;
	border: none;
	font-size: 18px;
	outline: none;
}
textarea{
	height: 110px;
	margin: 0;
	display: block;
}
.input_wrapper{
	position: relative;
	margin-bottom: $input_margin_bottom;
	font-size: $input_font_size;
	background: #fff;
	border: 1px solid $border_color_dark;

	%input_value_active_position{
		transform: translateY(20px);
		opacity: 0;
	}
	%input_hint_active_position{
		transform: translateY(0px);
		opacity: 1;
		transition-delay: 0.2s;
	}

	div, span{
		transition: $transition_1;
		color: lighten($font_color, 30%);
	}

	span{
		position: absolute;
		display: block;
		top: 0px;
		left: $ip_h - 12px;
		transform: translateY(-10px);
		opacity: 0;
		z-index: 1;
		font-size: 12px;
		// padding: 0 $ip_h;
	}

	input, textarea{
		background: none;
		outline: none;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		z-index: 2;
		position: relative;
		transition: $transition_1;

		&:focus{
			box-shadow: 0 0 10px 0 rgba($prime_color, .2);
			border-color: $prime_color;
		}

		&:focus + div{
			@extend %input_value_active_position;
		}
		&:focus ~ span{
			@extend %input_hint_active_position;
		}
	}
	&.active input + div,
	&.active textarea + div{
		@extend %input_value_active_position;
	}
	&.active input ~ span,
	&.active textarea ~ span{
		@extend %input_hint_active_position;
	}
	.input_value{
		position: absolute;
		top: 0;
		padding: $ip_v $ip_h - 12px;
		line-height: 20px;
	}
	&.error{
		color: $error;
		background: #FFF3F1;
		border-color: $error;

		input{
			border-color: $error;
			color: $error;
		}
	}
}
.error_message{
	background: $error;
	color: #fff !important;
	padding: 0 $ip_h;
	font-size: 16px;
} 

.select_wrapper{
	font-size: $input_font_size;
	line-height: 20px;
	position: relative;
	display: inline-block;
	margin-bottom: $input_margin_bottom;

	&.active{
		z-index: 999;

		.select_dropdown{
			display: block;
		}
		.select_form{
			border-color: $prime_color;
			&:after{
				transform: rotate(180deg);
			}
		}
	}
	&:hover{
		.select_form{
			border-color: $prime_color;
		}
	}

	.select_form{
		border:1px solid $border_color_dark;
		padding: $ip_v $ip_h;
		padding-right: $ip_h + 20;
		cursor: pointer;
		position: relative;
		background: #fff;
		transition:$transition_1;

		&:after{
			// content:'↓';
			content: '';
			background: url(../img/arrow_2.svg) center no-repeat;
			position: absolute;
			right: 10px;
			top:0;
			bottom: 0;
			margin:auto;
			height: 17px;
			width: 20px;
			text-align: center;
		}
	}

	.select_dropdown{
		box-shadow: $box_shadow;
		border:1px solid $border_color_dark;
		position: absolute;
		left: 0;
		right: 0;
		display: none;
		background: #fff;
		animation-name:select_dd;
		animation-duration: 0.3s;
		min-width:180px;
		max-height: 300px;
		overflow-x: scroll;

		.option{
			padding: 10px 20px;
			cursor: pointer;
			display: flex;

			&:hover{
				background:$sec_color;
			}
		}
	}
	@keyframes select_dd{
		0%{
			opacity: 0;
			transform: translateY(40px);
		}
	}
	.color_item{
		transform: translateY(1px);
	}
}

.pagination{
	display: flex;
	align-items:stretch;
	font-weight: 700;

	.pagination_btn{
		padding:0px 30px;
		border:1px solid $border_color_dark;
		align-items: center;
		display: flex;
		text-decoration: none;
		color:$font_color;
		font-size: 14px;
		transition:$transition_1;

		&:hover{
			background: $sec_color;
		}
	}
	.select_wrapper{
		margin: 0 -1px;
	}
	.select_dropdown{
		min-width:auto;
	}
}


.color_item{
	height: 16px;
	width: 16px;
	min-width: 16px !important;
	display: inline-flex;
	border: 1px solid rgba(0,0,0,0.10);
	margin: 0 10px 0 0;
	@extend %radius_100;
}

.color_filter{
	cursor: pointer;
	position: relative;
	&.checked{
		$x:3px;

		&:after{
			content: '';
			position: absolute;
			border: 1px solid $black;
			@extend %radius_100;
			top:-$x;
			bottom: -$x;
			left: -$x;
			right:-$x;
		}
	}
}


.social_link{
	display: inline-block;
	padding: 10px;
	opacity: 0.4;
	margin: 20px 0 0 0;
	transition: $transition_1;

	$social_icon_size:20px;

	svg{
		fill: $link_color;
		width: $social_icon_size;
		height: $social_icon_size;
		display: block;
	}
	&:hover{
		opacity: 1;
	}
}