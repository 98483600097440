
/****colors*****/
$prime_color: #FFC600;
$sec_color: #F8F6F2;
$font_color: #424242;
$font_light_clr: #666666;
$error: #FF3C0F;
$danger: #FD7D08;
$success: #0FCA5D;
$disable: #C0C0C0;
$btn_color: #692400;
$link_color: #3B73D5;
$border_color: #EBE9E2;
$border_color_dark: #DDD9CD;
$black: #1B1B1B;
$light_gray: #F8F6F2;



$page_padding: 7%;
$page_padding_min: 4.6%;
$border_radius: 0;
$transition_1: all 0.3s cubic-bezier(.42,0,.35,.9);
$text_max_width: 680px;

$screen_width_1:1220px;
$tablet_hor:1024px;
$tablet_ver:768px;
$mobile:540px;

$selector_control_size: 30px;

$box_shadow: 0 7px 30px 0 rgba(123,76,21,0.08);

$close_size: 60px;


%nav_link {
	display: inline-block;
	color: $prime_color;
	font-size: 11px;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 2px;
	position: relative;
}

%center_abs{
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	position: absolute;
}

%radius_100{
	border-radius:100%;
}

%overlay {
	opacity: 0.8;
	background: $black;
	position: fixed;
	z-index: 9;
	@extend %center_abs;
}

%marker{
	position: absolute;
	top:-5px;
	background: $prime_color;
	color: #fff;
	@extend %radius_100;
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 12px;
	text-align: center;
	letter-spacing: 0;
	visibility: hidden;
	box-shadow: 0 3px 5px 0 rgba(66,38,33,0.30);
}

%hum_after_before{
	content: '';
	position: absolute;
	top:0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	height: 2px;
	width: 22px;
	background: $font_color;
	transition: $transition_1;
}
