.home_1{
	display: flex;
	margin-top: -90px;

	@media screen and (max-width: $mobile) {
		flex-wrap: wrap;

		margin-top: -30px;
	}

	.col_2{
		box-sizing: border-box;
		padding: 180px $page_padding 80px;
		h2{
			margin-top: 0;
		}
		&:last-child{
			padding-left: 10%;
			
		}
		&:first-child{
			background:$sec_color;
		}
		@media screen and (max-width: $mobile) {
			width: 100%;
			padding: 40px $page_padding/2;
			&:last-child{
				padding-left: $page_padding/2;
			
			}
		}

	}
	.award_block{
		display: flex;
		margin-top: 60px;
		font-size: 14px;
		align-items: center;
		line-height: 25px;

		@media screen and (max-width: $mobile) {
			flex-wrap: wrap;
		}

		.img_wrapper{
			margin-right: 20px;
			width: 80%;

			img{
				width: 70%;
			}
			@media screen and (max-width: $mobile) {
				margin: 0 auto;
				img{
					margin: auto;
				}
			}
		}
		p{
			width: auto;
		}
	}
}

.clients{
	padding: 80px 0 60px;
	counter-reset: orderedlist;

	.into{
		display: flex;
		flex-wrap: wrap;
		@media screen and (max-width: $mobile) {
			justify-content: space-between;
		}
	}

	.item{
		.wrapper{
			padding: 40px;
			margin-right: 30px;
			margin-bottom: 30px;
			background:$sec_color;
			font-size: 14px;
			line-height: 20px;
			position: relative;
			@media screen and (max-width: $mobile) {
				margin-right: 0;
				padding:20px;
			}
			&:after{
				counter-increment: orderedlist;
			    content: '0' counter(orderedlist);
			    position:absolute;
			    top:40px;
			    left: 40px;
			    font-size:12px;
			    opacity: 0.6;
			    @media screen and (max-width: $mobile) {
			    	left: 20px;
			    	top:20px;
			    }
			}
			div{
				&:last-child{
					transform: translateY(5px);
					// text-transform: uppercase;
					// font-size: 11px;
				}
			}
		}
		.logo{
			height: 200px;
			display: flex;
			justify-content: center;
			align-items:center;
			filter: grayscale(1);
			transition: $transition_1;

			img{
				width: 100%;
				max-width: 120px;
				margin:auto;
				display: block;
				transform: translateY(5px);
			}
		}
		&:hover{
			.logo{
				filter: grayscale(0);
			}	
		}
	}
}

.home_grid{
	padding: 90px 0;
	@media screen and (max-width: $mobile) {
		padding: 0px 0 30px;
		.col_2{
			width: 100%;
		}
	}
	.into{
		&:first-child{
			display: flex;
			@media screen and (max-width: $mobile) {
				flex-wrap:wrap;
			}
		}
	}
	.img_wrapper{
		display: block;
		margin: 80px 0;
		text-decoration: none;
	}
	.item_1{
		margin-top: 80px;
	}
	.item_2{
		padding-left: 10%;
		box-sizing:border-box;
	}
	.item_3{
		margin: auto;
		padding: 0 30px 0 0 ;
		box-sizing:border-box;
	}
	@media screen and (max-width: $mobile) {
		.item_1, .item_2, .item_3{
			margin: 10px 0 30px !important;
			padding:0;
		}
	}
	button{
		margin-top: 20px;
	}
}