body{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;
	&.no_scroll{
		overflow-y: hidden;
		height: 100% ;
	}
}
.into{
	padding: 0 $page_padding;

	@media screen and (max-width: $mobile){
		padding: 0 $page_padding/2;
	}
}
.col_2{
	width: 50%;
	@media screen and (max-width: $mobile){
		width: 100%;
	}
}
.col_3{
	width: 33.33333%;
}
.col_3x2{
	width: 66.66666%;

}
.col_4{
	width: 25%;
	@media screen and (max-width: $tablet_ver){
		width: 50%;

	}
	@media screen and (max-width: $mobile){
		width: 46%;
	}
}
.p_l{
	padding-left: 10%;
}
.p_r{
	padding-right: 10%;
}

.col_4x3{
	width: 75%;
	@media screen and (max-width: $tablet_ver){
		width: auto;
	}
}
.img_wrapper{
	img{
		display: block;
		width: 100%;
	}
}
@media screen and (max-width: $mobile){
	.col_3{
		width: 100%;
	}
}