.table_wrapper{
	overflow-x: scroll;
}
table{
	width: 100%;
	border-collapse: collapse;

	thead{
		background: $sec_color;
		font-size: 16px;
	}
	td{
		padding: 10px 30px;
		border-bottom: 1px solid $border_color;
	}
	.disabled{
		color: $disable;
	}
}
.cell_r{
	text-align: right;
}
.cell_c{
	text-align: center;
}

.order_history_table{
	tr{
		cursor: pointer;
	}
}

.status_danger{
	color: $danger;
}
.status_success{
	color: $success;
}