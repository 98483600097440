.benefits{
	padding:90px 0;
	.into{
		display: flex;
		flex-wrap: wrap;
		.item{
			box-sizing: border-box;
			position: relative;
			padding: 0 40px 40px 0;
			font-size: 14px;
			line-height: 25px;
			@media screen and (max-width: $mobile){
				width: 100%;
				padding:0 0 10px;
				margin: 20px 0;
			}
			header{
				font-weight: 700;
				line-height: 40px;
				font-size: 28px;
				margin: 0 0 20px 0;
				@media screen and (max-width: $tablet_hor) {
					font-size: 24px;
				    line-height: 30px;
				    padding-top: 20px;
				    margin-bottom: 20px;
				}
				@media screen and (max-width: $mobile){
					padding-top: 0;
					margin-bottom: 10px;
				}
			}
			&:before{
				font-weight: 800;
				font-size: 70px;
				color: $light_gray;
				display: block;
				margin:0 0 10px -5px;
				line-height: 1;
				@media screen and (max-width: $mobile){
					margin-bottom: 0;
					font-size: 60px;
				}
			}
			&:after{
				content: '';
				position:absolute;
				left: 0;
				bottom: 0;
				height: 1px;
				width: 15%;
				background: $prime_color;
			}
			&:nth-child(1):before{
				content: '01.'
			}
			&:nth-child(2):before{
				content: '02.'
			}
			&:nth-child(3):before{
				content: '03.'
			}
			&:nth-child(4):before{
				content: '04.'
			}
		}
	}
}