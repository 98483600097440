.top_block{
	padding: 200px 0 40px;


	@media screen and (max-width: $mobile) {
		padding: 120px 0 40px;
	}
	h1{
		margin-top: 0px;
		padding-top: 30px;
		max-width: 100%;
		margin-right: 25%;
		@media screen and (max-width: $tablet_hor){
			padding-bottom: 10px;
			margin: 0 0 10px 0;
		}
	}
}
.dark_top{
	.top_block{
		background-color: black;
		background-size: cover;
		background-position:center;
		margin-bottom: 90px;
		

		@media screen and (max-width: $mobile) {
			margin-bottom: 30px;
		}
	}
	.top_block_content{
		margin-left: 25%;
		@media screen and (max-width: $tablet_hor){
			margin-left: 0;
			h1{
				margin-right: 0;
			}
		}
		h1{
			color: #fff;
		}
	}
}

.numbers{
	color: #fff;
	font-weight: 700;
	position:relative;
	font-size: 14px;
	line-height: 20px;
	margin: 0 0 -60px 0;
	transform: translateY(90px);
	@media screen and (max-width: $mobile){
		transform: translateY(80px);
		margin-top: 0;
		margin-bottom: 40px;
		.into{
			padding:0;
		}
	}

	.into{
		display: flex;
		// justify-content: flex-end;
	}
	.item{
		width: 25%;
		box-sizing:border-box;
		padding: 60px;
		@media screen and (max-width: $tablet_hor){
			padding:5%;
			width: 100%;
			
			@media screen and (max-width: $mobile){
				text-align: center;
			}
		}
		b{
			display: block;
			font-size: 60px;
			line-height: 1;
			margin-bottom: 10px;
			@media screen and (max-width: $tablet_hor){
				font-size: 38px;
			}
		}
		&.item_1{
			margin-left: 25%;
			background:$prime_color;
			@media screen and (max-width: $tablet_hor){
				margin: 0;
			}
		}
		&.item_2{
			background:lighten($prime_color, 7%);
			&:after{
				content: '2';
				background:#fff;
				position:absolute;
				right: 0;
				bottom: 0;
				top:0;
				left: 71.5%;
				@media screen and (max-width: $tablet_hor){
					display: none;
				}
			}
		}
	}
}