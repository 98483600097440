#m_menu{
	.modal_content_wrapper{
		display: flex;
		justify-content: flex-end;
	}
	.modal_content{
		min-height: 100vh;
		max-width: 600px;
		right: 0;
		padding:0;
		position: relative;
		animation-name: m_menu;
		animation-duration: 0.7s;
	}

	@keyframes m_menu {
		0%{
			transform: translateX(90px);
			opacity: 0;
		}
	}

	.nav{
		padding-top: 0px;
		padding-bottom: 50px;
		margin: 0;
		li{
			padding: 0;
			border-bottom: 1px solid $border_color;
			margin: 0;
			&:before{
				display: none;
			}
			
			a{
				color: $font_color;
				text-decoration: none;
				font-weight: normal;
				display: block;
				padding: 20px $page_padding;
				text-transform: uppercase;
			}
			& > a {
				font-weight: 700;
				line-height: 12px;
				font-size: 11px;

			}
			&.dd{
				padding-bottom:20px;
				div a{
					padding: 2px 40px;
					font-size: 11px;
				}
			}
		}
	}
	.m_menu_contacts{
		padding: 30px $page_padding 30px;
		background:$sec_color;
		line-height: 25px;
		font-size: 14px;
		a{
			text-decoration: none;
			font-weight: 700;
			color: $font_color;
		}
	}
}

#svg_logo{
	fill:#666;
	display: block;
	width: 40px;
	height: 40px;
	margin: 0 0 20px 0;
}