.global_overlay{
	@extend %overlay;
	// animation: modal_overlay 0.3s;
}

@keyframes modal_overlay{
	from{
		opacity: 0;
	}
}


.modal{
	position: fixed;
	top: 0;
	z-index: 10;
	display: none;
	width: 100%;
	height: 100%;
	overflow-x: scroll !important;
	-webkit-overflow-scrolling: touch;

	.modal_content_wrapper{
		width: 100%;
		margin: auto;
	}

	&.active{
		display: flex;
		align-items:stretch;
		justify-content: flex-end;

		&.center{
			
			.cross_close{
				position: absolute;
				// width: $page_padding*2;
			}
			.modal_content{
				margin: 0 $page_padding;
			}
		}
	}
	@media screen and (max-width: $mobile){
	}
}

$modal_content_padding: 50px;
.modal_content{
	text-align: left;
	background: #fff;
	padding: 0 $modal_content_padding 50px;
	overflow: hidden;
	min-height: 100%;
	min-width: 100px;
	height: inherit;
	box-sizing: border-box;
	animation: modal_content 1.3s forwards cubic-bezier(.165,.84,.44,1);
	position:relative;
	
	header{
		padding: 30px 0;

		.title{
			padding: 20px 0;
		}
	}
	@media screen and(max-width: $mobile){
		padding: 0 4%;
		header{
			.title{
				padding: 0;
			}
		}
		.btn{
			width: 100%;
		}
	}
}

@keyframes modal_content{
	0%{
		transform: translateY(20px) scale(0.95);
		opacity: 0;
	}

}

.cross_close{

	width: $close_size;
	height: $close_size; 
	position: fixed;
	right: 0;
	top:0;
	z-index: 2;
	cursor: pointer;

	&:after{
		@extend %hum_after_before;
		transform: rotate(45deg);
		animation: close_1 1s cubic-bezier(.165,.84,.44,1);
	}
	&:before{
		@extend %hum_after_before;
		transform: rotate(-45deg);
		animation: close_1 1s cubic-bezier(.165,.84,.44,1);
	}
	$close_translate: 30px;
	@keyframes close_1{
		0%{
			width: 6px;
			opacity: 0;
		}
		70%{
			width: 6px;
			opacity: 0;
		}	
	}
	@keyframes close_2{
		0%{
			transform: scale(0.5);
			opacity: 0;
		}
		50%{
			transform: scale(0.5);
			opacity: 0;
		}	
	}
	@media screen and (max-width: $mobile){
		height: 65px;
	}
}