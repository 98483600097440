.header{
	position:absolute;
	left: 0;
	right: 0;
	.into{
		display: flex;
		justify-content: space-between;
		align-items:center;
	}
	.top{
		background: $font_color;
		color: #fff;
		font-size: 12px;
		padding:3px 0;
		.contact{
			font-weight: 700;
			display: flex;
			align-items: center;
			@media screen and (max-width: $tablet_ver) {
				display: none;
			}
			a{
				color: #fff;
				text-decoration: none;
				margin-left: 10px;
				transition:$transition_1;
				padding-left: 15px;
				&:hover{
					color: $prime_color;
				}
			}
			.phones{
				background: url(../img/phone.svg) no-repeat left center;
			}
			.mail{
				border-right: 1px solid rgba($sec_color, .3);
				margin-right: 20px;
				padding-right:20px;
				background:url(../img/mail.svg) no-repeat left center;
			}
		}
	}
	.nav_ctn{
		padding: 30px 0;
		.nav{
			max-width: 100%;
			display: flex;
			font-weight: 700;
			font-size: 11px;
			text-transform: uppercase;
			align-items:center;
			@media screen and (max-width: 1360px) {
				display: none;
			}
			a{
				color: $font_color;
				text-decoration: none;
				padding:15px 13px;
				line-height: 15px;
				display: block;
				transition: $transition_1;
				&:hover{
					color: $prime_color;
				}
			}
			.btn{
				margin: 0 0 0 10px;
			}
			li{
				margin: 0;
				padding:0;
				&:before{
					display: none;
				}
				&.dd {
					& > a{
						position:relative;
						z-index: 2;
						&:after{
							content: '';
							background:url(../img/arrow_2.svg) no-repeat center;
							padding: 4px;
							margin-left: 2px;
						}
					}

					&:hover{
						z-index: 4;
						a {
							color: $prime_color;
						}
						& > div{
							display: block;
							&:after{
								animation: nav_pl .4s forwards cubic-bezier(.42,0,.35,.9);
							}
							a{
								animation: nav_a .6s forwards cubic-bezier(.42,0,.35,.9);
								color: darken(#fff, 30%);
							}
						}
					}

					& > div{
						position:absolute;
						display: none;
						// background:$sec_color;
						// top:0;
						padding:0 0 20px 0;
						a{
							padding: 10px 12px;
							z-index: 2;
							position:relative;
							color: #fff;
							font-weight: normal;
							&:hover{
								color: $prime_color;
							}
						}
						&:after{
							content: '';
							background: $font_color;
							position:absolute;
							top:-50px;
							bottom: 0px;
							$lef_righ: -20px;
							left: $lef_righ;
							right: $lef_righ;
							box-shadow: 0 10px 50px 0 rgba(66,66,66,0.28);
						}
					}
					@keyframes nav_pl{
						0%{
							transform: scale(1.1) translateY(-10%);
							opacity: 0;
						}
					}
					@keyframes nav_a{
						0%{
							opacity: 0;
						}
						20%{
							opacity: 0;
						}
					}

				}
			}
		}
	}
	.logo{
		height: 40px;
		width: 220px;
		background: url(../img/logo.svg) left center no-repeat;
		background-size:contain;

		@media screen and (max-width: $mobile){
			width: 160px;
		}
	}
}

.dd{
	font-weight: 700;
	.dd_link{
		cursor: pointer;
		&:after{
			content: '';
			background: url(../img/arrow_2_w.svg);
			margin-left: 4px;
			height: 5px;
			width: 5px;
		}
	}
	.dd_list{
		display: none;
		position:absolute;
		flex-direction: column;
		background: $font_color;
		padding: 10px 15px;
		transform: translateX(-15px);
		a{
			color: #fff;
			text-decoration: none;
		}
	}
	&:hover{
		.dd_list{
			display: flex;

		}
	}
	.lang_link{
		display: flex;
		align-items: center;
		// padding: 0 10px 0 0;
		&:before{
			content: '';
			width: 18px;
			height: 12px;
			margin-right: 8px;
			transform: translateY(-1px);
			background-size: cover;
			background-repeat: no-repeat;
			display: block;
			background-position: left center;
		}
		&.by:before{
			background-image: url(../img/flag_by.svg);
		}
		&.pl:before{
			background-image: url(../img/flag_pl.svg);
		}
		&.lt:before{
			background-image: url(../img/flag_lt.svg);
		}
		&.ua:before{
			background-image: url(../img/flag_ua.svg);
		}
		&.ru:before{
			background-image: url(../img/flag_ru.svg);
		}
	}
}

.dark_top{
	.header{
		.top{
			background: rgba($font_color, .4);
		}
		.logo{
			background-image:url(../img/logo_w.svg);
			display: block;
		}
		.nav_ctn{
			a{
				color: #fff;
			}
			.nav{
				.btn{
					background:#fff;
					border:none;
				}
				li.dd a:after{
					background-image: url(../img/arrow_2_w.svg);
				}

			}
		}
		.hum{
			div, div:after, div:before{
				background-color: #fff
			}
		}
	}
}

$hum_translate: 6px;

.hum{
	cursor: pointer;
	display: none;
	align-items: center;
	height: 40px;
	width: 40px;
	
	transform: translateX(9px);
	background:none;
	border:none;

	@media screen and (max-width: 1360px) {
		display: block;
	}

	div{
		@extend %hum_after_before;
		position: relative;
		&:after{
			@extend %hum_after_before;
			transform: translateY(- $hum_translate);
		}
		&:before{
			@extend %hum_after_before;
			transform: translateY($hum_translate);
		}
	}
	&:hover{
		// div{
		// 	transform: rotate(90deg);
		// }
	}
}

.hum_2{
	border:none;
	position: fixed;
	top:0;
	right:0;
	width:$page_padding;
	height:80px;
	z-index:3;
	display:none;
	background:#fff;
	div{
		@extend %hum_after_before;
		position: relative;
		background: $font_color;
		&:after{
			@extend %hum_after_before;
			transform: translateY(- $hum_translate);
		}
		&:before{
			@extend %hum_after_before;
			transform: translateY($hum_translate);
		}
	}
	&.active{
		display:block;
		transform: translateY(0);
		animation: hum_ani .5s cubic-bezier(.42,0,.35,.9) forwards;
	}
	@media screen and (max-width: $mobile) {
		width:$page_padding*2;
		height:50px;
	}
}

@keyframes hum_ani {
	0%{
		transform:translateY(-100%)
	}
}